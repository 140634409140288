import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import { connect } from "react-redux";
import { Box, Input, Rule, Mobile } from "../Bitter";
import { CategoriesContainer, ProfileContainer } from "../Containers";
import { Colors } from "../Helpers";
import { API } from "../Modules";
import Toast from "react-hot-toast";

const PayoutsScreen = ({ redux, core }) => {
  const { user } = redux;

  const [creatorDetails, setCreatorDetails] = useState();

  useEffect(() => {
    setCreatorDetails(user?.creatorDetails);
  }, [user]);

  const send = async () => {
    const response = await API.update(
      "/user/update/" + user._id,
      creatorDetails,
      localStorage.getItem("access_token"),
      "put"
    );

    if (response?.data?.updatedUser) {
      core("user", response.data.updatedUser);
      Toast((t) => response?.data.message);
    }
  };
  return (
    <Mobile
      render={(mobile) => (
        <>
          <CategoriesContainer />
          <Container>
            <Box
              height="100%"
              width="100%"
              direction="column"
              flex={1}
              display="flex"
              color="#FFFFFF"
              bottom={50}
            >
              <Row>
                <Col sm={12} md={4} lg={3} xxl={4}>
                  <ProfileContainer current="payouts" />
                </Col>
                <Col sm={12} md={8} lg={9} xxl={8}>
                  <Box>
                    <Box top={mobile ? 0 : 133} radius={8} width="100%">
                      <Box bottom={15}>
                        <Input
                          value={creatorDetails?.accountHolder}
                          change={(accountHolder) => {
                            setCreatorDetails({
                              ...creatorDetails,
                              accountHolder,
                            });
                          }}
                          name="Account Holder"
                        ></Input>
                      </Box>
                      <Box bottom={15}>
                        <Input
                          value={creatorDetails?.BICSwift}
                          change={(BICSwift) => {
                            setCreatorDetails({ ...creatorDetails, BICSwift });
                          }}
                          name="BIC / Swift"
                        ></Input>
                      </Box>
                      <Box bottom={30}>
                        <Input
                          value={creatorDetails?.IBAN}
                          change={(IBAN) => {
                            setCreatorDetails({ ...creatorDetails, IBAN });
                          }}
                          name="IBAN"
                        ></Input>
                      </Box>
                      <Box
                        height={40}
                        width={100}
                        color={Colors.primary}
                        display="flex"
                        align="center"
                        radius={8}
                        justify="center"
                      >
                        <Rule
                          press={send}
                          rule="BigWhite"
                          size={16}
                          line={16.4}
                        >
                          {" "}
                          Update
                        </Rule>
                      </Box>
                    </Box>
                  </Box>
                </Col>
              </Row>
            </Box>
          </Container>
        </>
      )}
    />
  );
};

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(PayoutsScreen);
