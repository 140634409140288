import React, { Component } from "react";
import { connect } from "react-redux";
import { Box, Button, Icon, Rule, Mobile } from "../Bitter";
import { Colors } from "../Helpers";
import { API, Auth } from "../Modules";
import LoginPopup from "./LoginPopup";
import { OuterClick } from "react-outer-click";

class MenuContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loginPopup: false, open: false };
  }

  async componentDidMount() {
    if (localStorage.getItem("user") && !this.props.redux.user) {
      if (JSON.parse(localStorage.getItem("user")).theatredor) {
        const response = await API.get(
          "/user/" + JSON.parse(localStorage.getItem("user")).theatredor
        );
        if (response?.data?.user) {
          localStorage.setItem("user", JSON.stringify(response.data.user));
          this.props.core("user", response.data.user);
          Auth.login(localStorage.getItem("access_token"), response.data.user);
        }
      }
    }

    const listview = await this.getListview();
    this.props.core("listview", listview);
    this.props.core("listviewloaded", true);
    const categories = await this.getCategories();
    this.props.core("categories", categories);
    const projects = await this.getProjects();
    this.props.core("projects", projects);
  }

  getCategories = async () => {
    try {
      const response = await API.get("/category");
      if (response?.data?.categories) {
        return response.data.categories;
      }
    } catch (Error) {
      return [];
    }
  };

  getProjects = async () => {
    try {
      const response = await API.get("/project");
      if (response?.data?.projects) {
        return response.data.projects;
      }
    } catch (err) {
      return [];
    }
  };

  getListview = async () => {
    try {
      const response = await API.get("/project/listview");
      if (response?.data?.projects) {
        return response.data.projects;
      }
    } catch (err) {
      return [];
    }
  };

  render() {
    const { redux } = this.props;
    const { open } = this.state;
    const { user, categories } = redux;

    const Item = ({ children }) => (
      <Box
        mode="padding"
        all={20}
        style={{ borderBottom: "1px solid #DDDDDD" }}
        display="flex"
        align="center"
      >
        {children}
      </Box>
    );

    return (
      <Mobile
        render={(mobile) => (
          <>
            {mobile && open ? (
              <Box
                mode="position"
                top={54}
                color="#FFFFFF"
                overflow="auto"
                position="fixed"
                left={0}
                bottom={0}
                right={0}
                height="calc(100% - 54px)"
                layer={3}
                style={{ borderTop: "1px solid #DDD" }}
              >
                <Item>
                  {!user ? (
                    <Box>
                      <Box
                        press={() => {
                          this.setState({
                            loginPopup: !this.state.loginPopup,
                            open: false,
                          });
                        }}
                      >
                        <Rule rule="Title" size={9}>
                          Register Using
                        </Rule>
                      </Box>

                      <Box display="flex" align="center">
                        <img
                          src="/assets/i/icon.png"
                          style={{ height: "12px", width: "12px" }}
                        ></img>
                        <Rule
                          left={5}
                          line={12}
                          rule="FooterTitle"
                          color={Colors.black}
                          size={9}
                        >
                          theatredor
                        </Rule>
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      <Box press={() => (window.location = "/profile")}>
                        <Rule rule="Title" size={9}>
                          {Auth.user().name}
                        </Rule>
                      </Box>

                      <Box display="flex" right={25} align="center">
                        <Rule
                          line={12}
                          rule="FooterTitle"
                          color={Colors.black}
                          size={9}
                          press={() => (window.location = "/profile")}
                        >
                          {Auth.user().username}
                        </Rule>
                        <Icon
                          icon="log-out"
                          size={9}
                          opacity={0.5}
                          left={7}
                          press={() => Auth.logout()}
                        />
                      </Box>
                    </Box>
                  )}
                </Item>
                {user ? (
                  <>
                    <Item>
                      <Rule to="/profilepledged" rule="Title">
                        Pledged Projects
                      </Rule>
                    </Item>
                    <Item>
                      <Rule to="/myprojects" rule="Title">
                        My Projects
                      </Rule>
                    </Item>
                    <Item>
                      <Rule to="/payouts" rule="Title">
                        Payouts
                      </Rule>
                    </Item>
                    <Item>
                      <Rule to="/analytics" rule="Title">
                        Analytics
                      </Rule>
                    </Item>
                    <Item>
                      <Rule to="https://theatredor.com" rule="Title">
                        Update Profile
                      </Rule>
                      <Icon
                        left={10}
                        size={16}
                        icon="arrow-right"
                        opacity={0.5}
                      />
                    </Item>
                  </>
                ) : undefined}
                <Item>
                  <Rule to="/personalverification" rule="Title">
                    Start a Project
                  </Rule>
                  <Icon left={10} size={16} icon="arrow-right" opacity={0.5} />
                </Item>
                <Item>
                  <Rule to="/" rule="Title">
                    Discover
                  </Rule>
                </Item>
                {categories?.map((category) => (
                  <Item>
                    <Rule
                      key={category._id}
                      rule="Title"
                      to={"/c/" + category.slug}
                    >
                      {category.name}
                    </Rule>
                  </Item>
                ))}
              </Box>
            ) : undefined}
            <Box
              display="flex"
              align="center"
              height={80}
              width="%100"
              color={Colors.white}
              justify="space-between"
              mode="padding"
              right={25}
              left={25}
            >
              {mobile ? undefined : (
                <Box display="flex">
                  <a href="/personalverification">
                    <Button>Start a Project</Button>
                  </a>
                  <Box display="flex" align="center" left={25}>
                    <Rule to="/" rule="Title">
                      Discover
                    </Rule>
                  </Box>
                </Box>
              )}
              <Box height={24} display="flex" align="center" justify="center">
                <a href="/">
                  <img
                    src="/assets/i/tagline.png"
                    style={{ height: "48px", width: "auto" }}
                  ></img>
                </a>
              </Box>
              {mobile ? undefined : (
                <Box display="flex" align="center">
                  <Box display="flex" align="center" right={25}>
                    <Icon
                      right={8.33}
                      icon="search"
                      size={12}
                      opacity="50%"
                    ></Icon>
                    <Rule rule="Title">Search</Rule>
                  </Box>
                  <Box
                    display="flex"
                    direction="column"
                    justify="center"
                    mode="position "
                    position="relative"
                  >
                    {!user ? (
                      <Box>
                        <Box
                          press={() => {
                            this.setState({
                              loginPopup: !this.state.loginPopup,
                            });
                          }}
                        >
                          <Rule rule="Title" size={9}>
                            Register Using
                          </Rule>
                        </Box>

                        <Box display="flex" right={25} justify="center">
                          <img
                            src="/assets/i/icon.png"
                            style={{ height: "12px", width: "12px" }}
                          ></img>
                          <Rule
                            left={5}
                            line={12}
                            rule="FooterTitle"
                            color={Colors.black}
                            size={9}
                          >
                            theatredor
                          </Rule>
                        </Box>
                      </Box>
                    ) : (
                      <Box>
                        <Box press={() => (window.location = "/profile")}>
                          <Rule rule="Title" size={9}>
                            {Auth.user().name}
                          </Rule>
                        </Box>

                        <Box
                          display="flex"
                          right={25}
                          justify="center"
                          align="center"
                        >
                          <Rule
                            line={12}
                            rule="FooterTitle"
                            color={Colors.black}
                            size={9}
                            press={() => (window.location = "/profile")}
                          >
                            {Auth.user().username}
                          </Rule>
                          <Icon
                            icon="log-out"
                            size={9}
                            opacity={0.5}
                            left={7}
                            press={() => Auth.logout()}
                          />
                        </Box>
                      </Box>
                    )}

                    <Box
                      right={0}
                      top="100%"
                      bottom="unset"
                      left="unset"
                      position="absolute"
                      mode="position"
                      layer={3}
                    >
                      {this.state.loginPopup ? (
                        <OuterClick
                          onOuterClick={() =>
                            this.setState({ loginPopup: false })
                          }
                        >
                          <div>
                            <LoginPopup
                              close={() => {
                                this.setState({ loginPopup: false });
                              }}
                            />
                          </div>
                        </OuterClick>
                      ) : undefined}
                    </Box>
                  </Box>
                </Box>
              )}
              {mobile ? (
                <Icon
                  icon="menu"
                  press={() => this.setState({ open: !open })}
                />
              ) : undefined}
            </Box>
            {mobile && this.state.loginPopup ? (
              <Box
                right={15}
                top={74}
                bottom="unset"
                left={15}
                position="fixed"
                mode="position"
                layer={4}
              >
                <OuterClick
                  onOuterClick={() => this.setState({ loginPopup: false })}
                >
                  <div>
                    <LoginPopup
                      close={() => {
                        this.setState({ loginPopup: false });
                      }}
                    />
                  </div>
                </OuterClick>
              </Box>
            ) : undefined}
          </>
        )}
      />
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(MenuContainer);
