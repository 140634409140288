import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { FooterContainer, MenuContainer } from "../Containers";
import PaymentPopup from "../Bitter/Organisms/PaymentPopup";

import {
  HomeScreen,
  CategoryScreen,
  SubCategoryScreen,
  OurRulesScreen,
  ProjectScreen,
  PersonalVerificationScreen,
  CreatorRequestScreen,
  StartProjectScreen,
  UpdateProjectScreen,
  ProfilePledgedScreen,
  MyProjectsScreen,
  AnalyticsScreen,
  SuccessScreen,
  FundingRestrictionScreen
} from "../Screens";
import PayoutsScreen from "../Screens/PayoutsScreen";

class Component extends React.PureComponent {
  render() {
    return (
      <Router>
        <MenuContainer />
        <Switch>
          <Route exact path="/" component={HomeScreen} />
          <Route exact path="/p/:project" component={ProjectScreen} />
          <Route path="/rules" component={OurRulesScreen} />
          <Route
            path="/c/:category/:subcategory"
            component={SubCategoryScreen}
          />
          <Route
            path="/s/:subcategory"
            component={SubCategoryScreen}
          />
          <Route path="/c/:category" component={CategoryScreen} />
          <Route
            path="/personalverification"
            component={PersonalVerificationScreen}
          />
          <Route path="/creatorrequirements" component={CreatorRequestScreen} />
          <Route path="/startproject" component={StartProjectScreen} />
          <Route path="/updateproject/:id" component={UpdateProjectScreen} />
          <Route path="/profilepledged" component={ProfilePledgedScreen} />

          <Route path="/profile" component={ProfilePledgedScreen} />
          <Route path="/myprojects" component={MyProjectsScreen} />
          <Route path="/payouts" component={PayoutsScreen} />
          <Route path="/analytics" component={AnalyticsScreen} />
          <Route path="/:userId/success" component={SuccessScreen} />
          <Route path="/funding-restriction" component={FundingRestrictionScreen} />
        </Switch>
        <FooterContainer />
      </Router>
    );
  }
}

export default Component;
