import React from "react";
import { connect } from "react-redux";

import { Resources } from "../../Helpers";

import EditorJS from "react-editor-js";
import Paragraph from "@editorjs/paragraph";
import Image from "@editorjs/image";
import Header from "@editorjs/header";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tools: undefined,
    };
  }

  render() {
    const { value, change, read = false } = this.props;
    const { tools } = this.state;

    return (
      <EditorJS
        readOnly={read}
        data={value}
        value={value}
        onChange={change ? (api, data) => change(data) : undefined}
        tools={{
          paragraph: Paragraph,
          image: Image,
          header: Header,
        }}
      />
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
