import React, { useState, useEffect } from "react";
import { Container } from "react-grid-system";
import { connect } from "react-redux";
import {
  Box,
  Content,
  Icon,
  Input,
  Rule,
  ProjectDetailsEditor,
  Date as DateInput,
} from "../Bitter";
import {
  CategoriesContainer,
  FooterContainer,
  MenuContainer,
} from "../Containers";
import { Colors, Resources } from "../Helpers";
import { API } from "../Modules";
import Toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

const StartProjectScreen = ({ redux }) => {
  const [images, setImages] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);
  const formatCurrentDate = () => {
    let today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    today = yyyy + "-" + mm + "-" + dd;

    return today;
  };

  const history = useHistory();

  const { categories, subcategories } = redux;

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    trailer: "",
    startDate: formatCurrentDate(),
    endDate: "",
    projectMilestone: 0,
    currency: "USD",
    category: [],
    subcategory: [],
  });

  const send = async () => {
    const projectFormData = new FormData();

    projectFormData.append("media", images);

    projectFormData.append("name", formData.name);
    images.forEach((image) => {
      projectFormData.append("media", image);
    });
    projectFormData.append("description", formData.description);
    projectFormData.append("startDate", formData.startDate);
    projectFormData.append("endDate", formData.endDate);
    projectFormData.append("endDateOriginal", formData.endDate);
    projectFormData.append("content", JSON.stringify(formData.content));
    projectFormData.append("trailer", formData.trailer);
    projectFormData.append(
      "projectMilestone",
      (formData.projectMilestone * 100).toString()
    );
    projectFormData.append("currency", formData.currency.toLowerCase());
    projectFormData.append("category", JSON.stringify(formData.category));
    projectFormData.append("subcategory", JSON.stringify(formData.subcategory));

    const response = await API.update(
      "/project/create",
      projectFormData,
      localStorage.getItem("access_token")
    );

    if (response?.data?.project) {
      Toast((t) => response?.data.message);
    }

    setFormData({
      name: "",
      description: "",
      trailer: "",
      startDate: formatCurrentDate(),
      endDate: "",
      projectMilestone: 0,
      currency: "USD",
      category: [],
      subcategory: [],
    });

    history.push("/");
  };

  useEffect(() => {
    if (images.length < 1) return;
    const newImageURLs = [];
    images.forEach((image) => newImageURLs.push(URL.createObjectURL(image)));
    setImageURLs(newImageURLs);
  }, [images]);

  function onImageChange(e) {
    setImages([...images, ...e.target.files]);
  }
  return (
    <>
      <CategoriesContainer />
      <Container>
        <Box height="100%" width="100%" display="flex" justify="center">
          <Box
            direction="column"
            flex={1}
            display="flex"
            color="#FFFFFF"
            align="center"
            justify="center"
          >
            <Box top={50} width="100%" style={{ maxWidth: "730px" }}>
              <Box display="flex">
                <Rule rule="Project" size={32} line={32.8}>
                  Start a Project
                </Rule>

                <Rule left={20} rule="Goal" size={32} line={32.8}>
                  Step 3/3
                </Rule>
              </Box>
              <Box top={50}>
                <Box bottom={15}>
                  <Input
                    value={formData.name}
                    change={(name) => setFormData({ ...formData, name })}
                    name="Project Title"
                    placeholder="Project Title"
                  ></Input>
                </Box>

                <Box bottom={15}>
                  <Input
                    value={formData.description}
                    change={(description) =>
                      setFormData({ ...formData, description })
                    }
                    name="Project Description"
                    placeholder="Project Description"
                    wide={true}
                  ></Input>
                </Box>
                <Box bottom={15}>
                  <Input
                    value={formData.trailer}
                    change={(trailer) => setFormData({ ...formData, trailer })}
                    name="Trailer Link"
                    placeholder="Link to your trailer"
                  ></Input>
                </Box>
                <Rule rule="TitleRule" size={14} line={16.8}>
                  Attachments
                </Rule>
                <Rule top={5} rule="Title" size={14} line={16.8}>
                  Images will be displayed inside a media slider.
                </Rule>
                <Box display="flex" align="center" top={15}>
                  <Box radius={8} styling="image-upload" display="flex">
                    <input
                      id="file-input"
                      multiple
                      accept="image/*"
                      type="file"
                      name="files"
                      onChange={onImageChange}
                    />
                    {imageURLs.map((imageSrc) => (
                      <Box display="flex" left={5} bottom={5}>
                        <img
                          src={imageSrc}
                          style={{
                            height: "48px",
                            borderRadius: 8,
                            width: "48px",
                          }}
                        ></img>
                      </Box>
                    ))}
                    <label
                      for="file-input"
                      style={{
                        height: 48,
                        width: 48,
                        backgroundColor: "#f7f7f7",
                        marginLeft: 5,
                        marginRight: 5,
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 8,
                        cursor: "pointer",
                      }}
                    >
                      <Icon icon="plus" color="#ccc"></Icon>
                    </label>
                  </Box>
                </Box>
                <Box top={15} bottom={15}>
                  <DateInput
                    value={formData.startDate}
                    change={(startDate) =>
                      setFormData({ ...formData, startDate: startDate })
                    }
                    name="Start Date"
                  ></DateInput>
                </Box>
                <Box bottom={15}>
                  <DateInput
                    value={formData.endDate}
                    change={(endDate) => setFormData({ ...formData, endDate })}
                    name="End Date"
                  ></DateInput>
                </Box>
                <Box display="flex">
                  <Box bottom={15} width="70%" right={30}>
                    <Input
                      value={formData.projectMilestone}
                      change={(projectMilestone) =>
                        setFormData({ ...formData, projectMilestone })
                      }
                      name="Project Milestone"
                    ></Input>
                  </Box>
                  <Box bottom={15} width="30%">
                    <Input
                      value={formData.currency}
                      change={(currency) =>
                        setFormData({ ...formData, currency })
                      }
                      name="Currency"
                      placeholder="USD"
                      mode="select"
                      options={[
                        { _id: "usd", name: "USD" },
                        { _id: "eur", name: "EUR" },
                        { _id: "gbp", name: "GBP" },
                      ]}
                    ></Input>
                  </Box>
                </Box>
                <Box top={50}>
                  <ProjectDetailsEditor
                    content={formData.content}
                    update={(content) => {
                      setFormData({ ...formData, content });
                    }}
                  />
                </Box>
                <Box top={50} bottom={15}>
                  <Input
                    mode="select"
                    options={categories}
                    name="Category"
                    value={formData.category[0]}
                    change={(category) =>
                      setFormData({ ...formData, category: [category] })
                    }
                  ></Input>
                </Box>
                <Box bottom={15}>
                  <Input
                    disabled={formData.category.length === 0}
                    mode="select"
                    name="Subcategory"
                    value={formData.subcategory[0]}
                    options={subcategories?.filter(
                      (subcategory) =>
                        subcategory.parentCategory === formData.category[0]
                    )}
                    change={(subcategory) => {
                      setFormData({ ...formData, subcategory: [subcategory] });
                      console.log(formData);
                    }}
                  ></Input>
                </Box>
              </Box>

              <Box
                top={50}
                bottom={50}
                display="flex"
                align="center"
                justify="center"
                color={Colors.primary}
                width={115}
                height={40}
                radius={8}
              >
                <Rule press={send} rule="BigWhite" size={16} line={16.4}>
                  Continue
                </Rule>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(StartProjectScreen);
