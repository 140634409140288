import React from "react";

import { Box, Rule } from "../";
import { Colors } from "../../Helpers";

const Input = (props) => {
  const {
    placeholder = "",
    name = "",
    wide = false,
    active = true,
    value = "",
    change = false,
    mode = "input",
    options = [],
    disabled = false,
    type,
  } = props;
  return (
    <Box display="flex" direction="column">
      <Box bottom={5}>
        <Rule
          style={{ opacity: disabled && 0.5 }}
          rule="TitleRule"
          size={14}
          line={16.8}
        >
          {name}
        </Rule>
      </Box>
      {mode === "select" ? (
        <select
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onChange={(event) => {
            if (disabled) {
              return;
            }
            if (change) {
              change(event.target.value);
            }
          }}
          style={{
            border: "1px solid #D6DDEB",
            width: "100%",
            boxSizing: "border-box",
            paddingLeft: "12px",
            paddingTop: "11px",
            paddingBottom: "12px",
            fontFamily: "Lufga",
            fontWeight: "400",
            fontSize: "14px",
            height: "40px",
          }}
        >
          <option value="" disabled selected={value === ""}>
            Select
          </option>

          {options.map((option) =>
            typeof option === "object" ? (
              <option
                selected={value === option._id}
                value={option._id}
                key={option._id}
              >
                {option.name.replace("%2F", "/")}
              </option>
            ) : (
              <option selected={value === option} value={option} key={option}>
                {option}
              </option>
            )
          )}
        </select>
      ) : (
        <Box
          height={wide ? "100px" : "40px"}
          width="100%"
          align="center"
          radius={8}
          style={{ border: "1px solid #DDDDDD" }}
        >
          <input
            value={value}
            type={type}
            disabled={disabled}
            onChange={(e) => {
              if (disabled) {
                return;
              }
              if (change && !disabled) {
                change(e.target.value);
              }
            }}
            placeholder={placeholder}
            style={{
              background: Colors.gray,
              fontFamily: "Lufga",
              fontWeight: "400",
              fontSize: "14px",
              paddingLeft: "12px",
              paddingTop: "11px",
              paddingBottom: "12px",
              width: "100%",
              opacity: disabled && 0.5,
            }}
          ></input>
        </Box>
      )}
    </Box>
  );
};

export default Input;
