import React from "react";

import { Box, Rule, Icon } from "../../Bitter";
import { Colors, Functions } from "../../Helpers";
import { API } from "../../Modules";

import Moment from "moment";

const ProjectCard = ({
  title = "",
  id = 0,
  status = "",
  order = "",
  backers = 0,
  fund = 0,
  goal = 0,
  image = "",
  description = "",
  currency = "",
  project,
  publicity = false,
  update = false,
}) => {
  const days = Moment(project?.endDate).diff(Moment(), "days");

  const download_donators = async () => {
    const donators = await API.get("/project/" + id + "/donators");

    if (donators.error) {
      return false;
    }

    donators.data.donators.map((donator) => {
      let temporary = [];

      temporary.push({
        name: donator.user.name + " " + donator.user.surname,
        email: donator.user.email,
        donationAmount: (() => {
          try {
            return parseFloat(donator.donationAmount / 100).toFixed(2);
          } catch (Error) {
            console.error(Error);
            return donator.donationAmount;
          }
        })(),
        projectCurrency: donator.projectCurrency,
        donationCurrency: donator.donationCurrency,
        refunded: donator.refunded ? "1" : "0",
      });

      Functions.xlsx(temporary, {
        name: "Name",
        email: "Email",
        donationAmount: "Donation Amound",
        projectCurrency: "Project Currency",
        donationCurrency: "Donation Currency",
        refunded: "Refunded",
      });
    });
  };

  return (
    <Box display="flex" align="flex-start" width="100%" top={30}>
      <Box
        width={110}
        height={110}
        display="block"
        radius={8}
        image={"url(" + image + ")"}
        color="#F7F7F7"
        style={{ minWidth: 110 }}
      >
        {update ? (
          <Box
            press={() => {
              window.location = "/updateproject/" + id;
            }}
            width={24}
            height={24}
            radius={4}
            shadow="small"
            left={4}
            top={4}
            color="#FFFFFF"
            display="inline-flex"
            align="center"
            justify="center"
          >
            <Icon color="#909090" icon="edit" size={12} />
          </Box>
        ) : undefined}
      </Box>

      <Box flex={1} left={20} display="flex" direction="column">
        <Box display="flex" align="center">
          <a href={"/p/" + id}>
            <Rule rule="Project" size={16} line={16.4}>
              {title}
            </Rule>
          </a>
          {publicity ? (
            <>
              {(() => {
                switch (publicity) {
                  case "live":
                    return (
                      <>
                        {" "}
                        <Box
                          height={20}
                          display="inline-flex"
                          align="center"
                          color="#C2FFDE"
                          justify="center"
                          radius={8}
                          style={{ paddingLeft: "8px", paddingRight: "8px" }}
                          left={12}
                        >
                          <Icon
                            icon="check"
                            size={10}
                            style={{ position: "relative", bottom: 0 }}
                            right={6}
                            color="#018E42"
                          />
                          <Rule
                            rule="PomeProgramme"
                            color="#018E42"
                            size={10}
                            line={12}
                          >
                            Live
                          </Rule>
                        </Box>
                        <Box
                          height={20}
                          display="inline-flex"
                          align="center"
                          color="#e76f51"
                          justify="center"
                          radius={8}
                          style={{
                            paddingLeft: "8px",
                            paddingRight: "8px",
                            marginLeft: 10,
                          }}
                          left={12}
                          press={download_donators}
                        >
                          <Icon
                            icon="download-cloud"
                            size={10}
                            style={{ position: "relative", bottom: 0 }}
                            right={6}
                            color="#fff"
                          />
                          <Rule
                            rule="PomeProgramme"
                            color="#fff"
                            size={10}
                            line={12}
                          >
                            Download Donators
                          </Rule>
                        </Box>
                      </>
                    );
                    break;
                  default:
                    return (
                      <Box
                        height={20}
                        display="inline-flex"
                        align="center"
                        color="#F7F7F7"
                        justify="center"
                        radius={8}
                        style={{ paddingLeft: "8px", paddingRight: "8px" }}
                        left={12}
                      >
                        <Icon
                          icon="eye"
                          size={10}
                          style={{ position: "relative", bottom: 0 }}
                          right={6}
                          color="#555555"
                        />
                        <Rule
                          rule="PomeProgramme"
                          color="#555555"
                          size={10}
                          line={12}
                        >
                          In Review
                        </Rule>
                      </Box>
                    );
                }
              })()}
            </>
          ) : (
            <>
              <Rule right={10} left={10} rule="Pome" size={3}>
                {"\u2B24"}
              </Rule>
              <Rule rule="Pome">
                {days > 0 ? days + " days to go" : "Finished"}
              </Rule>
            </>
          )}
          {order.length === 0 ? null : (
            <Box
              radius={8}
              display="flex"
              align="center"
              justify="center"
              left={10}
              height={20}
              width={130}
              style={{
                background:
                  "linear-gradient(to right,rgba(233, 238, 242, 1), rgba(247, 249, 251, 1))",
              }}
            >
              <Rule rule="Order">{order}</Rule>
            </Box>
          )}
        </Box>
        <Box top={11}>
          <Rule rule="Dark">{description}</Rule>
        </Box>
        <Box display="flex" align="center" justify="flex-start" top={9}>
          <Box
            height={20}
            display="flex"
            align="center"
            color={Colors.pinky}
            justify="center"
            radius={8}
            style={{ paddingLeft: "8px", paddingRight: "8px" }}
          >
            <Rule rule="PomeProgramme" size={10} line={12}>
              {fund}/{goal} {currency.toUpperCase()}
            </Rule>
          </Box>
          <Box
            left={10}
            height={20}
            width="auto"
            display="flex"
            align="center"
            color={Colors.pinky}
            radius={8}
            justify="center"
          >
            <Rule
              rule="PomeProgramme"
              size={10}
              line={12}
              style={{ paddingLeft: "8px", paddingRight: "8px" }}
            >
              {backers} Backers
            </Rule>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectCard;
