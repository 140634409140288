import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container } from "react-grid-system";
import { Box, Input, Rule, Loading } from "../Bitter";
import {
  CategoriesContainer,
  FooterContainer,
  MenuContainer,
} from "../Containers";
import { Colors, Functions, Resources } from "../Helpers";
import { API, Auth } from "../Modules";

import Persona from "persona";
import Toast from "react-hot-toast";

const PersonalVerificationScreen = ({ redux, core }) => {
  const { user } = redux;

  const [loading, _loading] = useState(true);

  useEffect(() => {
    if (!user.premium) {
      window.location = "/funding-restriction";
      return false;
    }

    if (
      (user.isVerified && !user.metadata.length) ||
      !Resources.persona_required
    ) {
      window.location = "/creatorrequirements";
      return false;
    }

    if (user.metadata.length) {
      window.location = "/startproject";
      return false;
    }

    const client = new Persona.Client({
      templateId: "itmpl_RM6MHPCCkGAHWfNDz29T3hRE",
      environment: "sandbox",
      onReady: () => client.open(),
      onComplete: async (props) => {
        const response = await API.update(
          "/user/update/" + user._id,
          {
            personaInquiryId: props.inquiryId,
            isVerified: true,
            personaInquiryStatus: "pending",
          },
          localStorage.getItem("access_token"),
          "put"
        );

        if (response?.data?.updatedUser) {
          core("user", response.data.updatedUser);
          Toast.success(
            "Please come back to this page after your verification is complete."
          );
          Auth.update(response.data.updatedUser);

          await Functions.sleep(1000);

          window.location = "/creatorrequirements";
        }
      },
      onCancel: (props) => console.log(props),
      onError: (error) => console.log(error),
    });

    _loading(false);
  }, []);

  return (
    <>
      <Loading loading={loading} />
      <CategoriesContainer />
      <Container>
        <Box height="100%" width="100%" display="flex" justify="center">
          <Box
            direction="column"
            flex={1}
            display="flex"
            color="#FFFFFF"
            align="center"
            justify="center"
          >
            <Box top={50} style={{ maxWidth: "730px" }}>
              <Box display="flex">
                <Rule rule="Project" size={32} line={32.8}>
                  Personal Verification
                </Rule>

                <Rule left={20} rule="Goal" size={32} line={32.8}>
                  Step 1/3
                </Rule>
              </Box>
              <Rule top={20} rule="SmallRule">
                Before we start, we need to verify your identity and business
                information for you to start a project.
              </Rule>
              <Box
                top={50}
                width="100%"
                height={250}
                radius={8}
                color={Colors.grey}
                style={{ border: "1px solid rgba(221, 221, 221, 1)" }}
              ></Box>
              <a href="/creatorrequirements">
                <Box
                  top={50}
                  bottom={50}
                  display="flex"
                  align="center"
                  justify="center"
                  color={Colors.primary}
                  width={115}
                  height={40}
                  radius={8}
                >
                  <Rule rule="BigWhite" size={16} line={16.4}>
                    Continue
                  </Rule>
                </Box>
              </a>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(PersonalVerificationScreen);
