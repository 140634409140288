import React from "react";
import "../Styles/doughnut.css";

class Calculus {
  static slices(slices, radius, size, border) {
    let previous = 0;
    return slices.map((slice) => {
      const commands = {
        ...slice,
        commands: this.commands(slice, radius, size, border),
        offset: previous * 3.6 * -1,
      };
      previous += slice.percent;
      return commands;
    });
  }

  static commands(slice, radius, size, border) {
    const degrees = this.degrees(slice.percent);
    const flag = degrees > 180 ? 1 : 0;
    const inner = radius - border;

    const commands = [];
    commands.push(`M ${size / 2 + radius} ${size / 2}`);
    commands.push(
      `A ${radius} ${radius} 0 ${flag} 0 ${this.coords(degrees, radius, size)}`
    );
    commands.push(`L ${this.coords(degrees, inner, size)}`);
    commands.push(
      `A ${inner} ${inner} 0 ${flag} 1 ${size / 2 + inner} ${size / 2}`
    );
    return commands.join(" ");
  }

  static coords(angle, radius, size) {
    const x = Math.cos((angle * Math.PI) / 180);
    const y = Math.sin((angle * Math.PI) / 180);
    const coordX = x * radius + size / 2;
    const coordY = y * -radius + size / 2;
    return [coordX, coordY].join(" ");
  }

  static degrees(percent) {
    return percent * 3.6;
  }
}

export default ({
  radius = 50,
  box = 100,
  border = 16.7,
  press,
  size = 150,
  bottom = 0,
  label = false,
  percentage = 25,
  color = "#F00000",
}) => {
  const data = [{ id: 1, percent: percentage, color }];
  return (
    <div
      style={{ width: size, height: size, marginBottom: bottom }}
      className="bitter-doughnut"
    >
      <div className="tooltip">
        {label
          ? typeof label === "object"
            ? label.map((label) =>
                typeof label === "object" ? (
                  <span style={{ ...label.style }}>{label.text}</span>
                ) : (
                  <span>{label}</span>
                )
              )
            : label
          : percentage}
      </div>

      <svg
        viewBox={"0 0 " + box + " " + box}
        style={{ width: "100%", height: "100%" }}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100ZM50 83.3333C68.4095 83.3333 83.3333 68.4095 83.3333 50C83.3333 31.5905 68.4095 16.6667 50 16.6667C31.5905 16.6667 16.6667 31.5905 16.6667 50C16.6667 68.4095 31.5905 83.3333 50 83.3333Z"
          fill="url(#paint0_linear_361_953)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_361_953"
            x1="50"
            y1="2.52308e-06"
            x2="69.1797"
            y2="-7.27328"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E9EEF2" />
            <stop offset="1" stop-color="#F7F9FB" />
          </linearGradient>
        </defs>
        {Calculus.slices(data, radius, box, border).map((slice) => (
          <path
            onClick={() => press(slice)}
            fill={slice.color}
            d={slice.commands}
            transform={"rotate(" + slice.offset + ")"}
            className="bitter-doughnut"
          ></path>
        ))}
      </svg>
    </div>
  );
};
