import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import { Box, Rule } from "../Bitter";
import { connect } from "react-redux";
import { API } from "../Modules";

const FooterContainer = ({ core, redux }) => {
  const { subcategories } = redux;

  const getSubcategories = async () => {
    const response = await API.get("/subcategory");
    if (response?.data?.subcategories) {
      core("subcategories", response.data.subcategories);
    }
  };

  useEffect(() => {
    getSubcategories();
  }, []);
  return (
    <>
      <Box
        image="linear-gradient(180deg, #F7F7F7 -100%, #FFFFFF 100%)"
        mode="padding"
        bottom={25}
      >
        <Container>
          <Row>
            <Col xs={6} md={2} lg={2} xxl={2}>
              <img
                style={{ height: "24px", width: "87.09px", marginTop: "25px" }}
                src="/assets/i/logotheatredor.png"
              ></img>
            </Col>
            <Col xs={6} md={2} lg={2} xxl={2}>
              <Box display="flex" direction="column">
                <Rule rule="FooterTitle" top={29.5}>
                  About
                </Rule>
                <Rule top={10} rule="Content" target="_blank" to="https://theatredor.com/about-us/">
                  About Us
                </Rule>
                <Rule top={10} rule="Content" target="_blank" to="https://theatredor.com/">
                  Theatredor
                </Rule>
                <Rule top={10} rule="Content" target="_blank" to="https://theatredor.com/news">
                  News
                </Rule>
                <Rule top={10} rule="Content" target="_blank" to="https://theatredor.com/blog">
                  Blog
                </Rule>
              </Box>
            </Col>
            <Col xs={6} md={2} lg={2} xxl={2}>
              <Box display="flex" direction="column">
                <Rule rule="FooterTitle" top={29.5}>
                  Support
                </Rule>
                <Rule top={10} rule="Content" target="_blank" to="https://theatredor.com/contact-us">
                  Contact Us
                </Rule>
                <Rule top={10} rule="Content" target="_blank" to="https://theatredor.com/contact-us">
                  Help Center
                </Rule>
                <Rule top={10} rule="Content" to="/rules">
                  Rules
                </Rule>
                <Rule top={10} rule="Content" target="_blank" to="https://theatredor.com/contact-us">
                  FAQ
                </Rule>
              </Box>
            </Col>
            <Col xs={6} md={2} lg={2} xxl={2}>
              <Box display="flex" direction="column">
                <Rule rule="FooterTitle" top={29.5}>
                  Socials
                </Rule>
                <Rule top={10} rule="Content" target="_blank" to="https://www.instagram.com/theatre_dor/">
                  Instagram
                </Rule>
                <Rule top={10} rule="Content" target="_blank" to="https://twitter.com/TheatredorS">
                  Twitter
                </Rule>
              </Box>
            </Col>
            <Col xs={6} md={2} lg={2} xxl={2}>
              <Box display="flex" direction="column">
                <Rule rule="FooterTitle" top={29.5}>
                  Categories
                </Rule>
                {subcategories?.slice(0, 6).map((subcategory) => (
                  <Rule key={subcategory._id} top={10} rule="Content" to={"/s/" + subcategory.slug}>
                    {subcategory.name}
                  </Rule>
                ))}
              </Box>
            </Col>
            <Col xs={6} md={2} lg={2} xxl={2}>
              <Box display="flex" direction="column">
                <Rule rule="FooterTitle" top={29.5}>
                  More
                </Rule>
                <Rule top={10} rule="Content" to="/personalverification">
                  Start a Project
                </Rule>
                <Rule top={10} rule="Content" to="/rules">
                  Become a Creator
                </Rule>
                <Rule top={10} rule="Content" target="_blank" to="https://theatredor.com/news">
                  News
                </Rule>
              </Box>
            </Col>
          </Row>
        </Container>
      </Box>
      <Box image="linear-gradient(180deg, #F7F7F7 -100%, #FFFFFF 100%)">
        <Container>
          <Row>
            <Box display="flex" align="center" width="%100" mode="padding" top={20} bottom={10} wrap="wrap">
              <Rule rule="Content" left={25} bottom={10}>
                Trust & Safety
              </Rule>
              <Rule rule="Content" left={25} bottom={10} target="_blank" to="https://theatredor.com/terms-of-service">
                Terms of Service
              </Rule>
              <Rule rule="Content" left={25} bottom={10} target="_blank" to="https://theatredor.com/privacy-policy">
                Privacy Policy
              </Rule>
              <Rule rule="Content" left={25} bottom={10} target="_blank" to="https://theatredor.com/cookie-policy">
                Cookie Policy
              </Rule>
            </Box>
          </Row>
        </Container>
      </Box>
    </>
  );
};

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(FooterContainer);
