import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-grid-system";
import { Box, Button, Icon, Rule, Mobile } from "../Bitter";
import { Colors } from "../Helpers";
import { API } from "../Modules";

class FundingRestrictionScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {}

  render() {
    return (
      <Container>
        <Row align="center" justify="center">
          <Col xs={12} sm={12} md={6} lg={6} align="center" justify="center">
            <img
              src="/assets/i/funding-restriction.png"
              className="--block --center --restriction"
            />
            <Rule
              size={32}
              weight="600"
              display="block"
              align="center"
              bottom={24}
            >
              Creating a project is restricted to our premium members.
            </Rule>
            <Rule size={24} display="block" align="center" bottom={24}>
              You can check out our membership options and create a project.
            </Rule>
            <Rule
              size={18}
              opacity={0.5}
              display="block"
              align="center"
              bottom={24}
            >
              To access the membership options, click on the button down below.
            </Rule>
            <Button
              to="https://theatredor.com/upgrade-your-membership/"
              size="large"
              bottom={24}
            >
              Membership Options
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(FundingRestrictionScreen);
