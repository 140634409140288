import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-grid-system";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import {
  BigProjectCard,
  Box,
  ProjectsDetails,
  Rule,
  SmallProjectCardSkeleton,
  Editor,
} from "../Bitter";
import PaymentPopup from "../Bitter/Organisms/PaymentPopup";
import { FooterContainer, MenuContainer } from "../Containers";
import { Colors, Functions } from "../Helpers";
import Confetti from "react-confetti";
import { API } from "../Modules";

import Helmet from "react-helmet";
import { connect } from "react-redux";

const ProjectScreen = ({ core, redux }) => {
  const screenParams = useParams();
  const [projectDetails, setProjectDetails] = useState();
  const { search } = useLocation();
  const { session_id } = queryString.parse(search);

  const [contentIndex, setContentIndex] = useState(0);

  const [paymentInformation, setPaymentInformation] = useState({
    session: null,
    customer: null,
  });
  const getPaymentInformation = async () => {
    // this will return session and customer information
    const response = await API.get(
      redux.user
        ? `/payment/payment-success/${session_id}`
        : `/payment/payment-unregistered-success/${session_id}`,
      localStorage.getItem("access_token")
    );
    if (response?.data?.session && response?.data?.customer) {
      setPaymentInformation({
        session: response.data.session,
        customer: response.data.customer,
      });
      core("user", response.data.donator);
      setProjectDetails(response.data.project);

      setPaymentPopupOpen(true);
    }
  };

  const [paymentPopupOpen, setPaymentPopupOpen] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);

  useEffect(() => {
    if (session_id) {
      getPaymentInformation();
    }
  }, []);

  useEffect(async () => {
    const response = await API.get("/project/" + screenParams.project);
    if (response?.data) {
      setProjectDetails(response.data.project);
      setContentLoading(false);
    }
  }, []);

  return (
    <>
      {projectDetails ? (
        <Helmet>
          <title>{projectDetails?.name}</title>
          <meta name="og:title" content={projectDetails?.name} />
          <meta
            name="og:url"
            content={"crowdfunding.theatredor.com/p/" + projectDetails._id}
          />
          <meta name="og:description" content={projectDetails?.description} />
          <meta name="og:type" content="article" />
          <meta name="og:image" content={projectDetails?.assets[0]?.access} />

          <meta name="twitter:title" content={projectDetails?.name} />
          <meta
            name="twitter:url"
            content={"crowdfunding.theatredor.com/p/" + projectDetails._id}
          />
          <meta name="twitter:site" content="crowdfunding.theatredor.com" />
          <meta name="twitter:creator" content="TheatredorS" />
          <meta
            name="twitter:description"
            content={projectDetails?.description}
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:image"
            content={projectDetails?.assets[0]?.access}
          />
        </Helmet>
      ) : undefined}
      {paymentPopupOpen && (
        <PaymentPopup
          project={projectDetails}
          session={paymentInformation.session}
          customer={paymentInformation.customer}
          press={() => setPaymentPopupOpen(false)}
        />
      )}
      <Container>
        <Box
          top={25}
          bottom={25}
          height="100%"
          width="100%"
          direction="column"
          flex={1}
          display="flex"
          color="#FFFFFF"
        >
          {projectDetails ? (
            <Row>
              <Col sm={12} md={7} lg={7} xxl={7}>
                <BigProjectCard project={projectDetails} carousel={true} />
              </Col>
              <Col sm={12} md={5} lg={5} xxl={5}>
                <ProjectsDetails project={projectDetails}></ProjectsDetails>
              </Col>
            </Row>
          ) : undefined}
        </Box>
      </Container>
      <Box
        height={66}
        width="%100"
        display="flex"
        align="center"
        justify="center"
        image="linear-gradient(180deg, #F7F7F7 -100%, #FFFFFF 100%)"
      >
        <Box display="flex" align="center" justify="center" wrap={50}>
          {projectDetails?.content?.map((item, c) => (
            <Box
              right={12}
              left={12}
              style={{
                paddingBottom: 5,
                borderBottom:
                  c === contentIndex
                    ? "2px solid #F00000"
                    : "2px solid transparent",
              }}
              press={async () => {
                setContentLoading(true);
                setContentIndex(c);
                await Functions.sleep(1);
                setContentLoading(false);
              }}
            >
              <Rule rule="Categories">{item.name}</Rule>
            </Box>
          ))}
        </Box>
      </Box>
      <Box mode="padding" top={20} bottom={20}>
        <Container>
          {contentLoading ? null : (
            <Editor
              value={{ blocks: projectDetails?.content[contentIndex]?.blocks }}
              read={true}
            />
          )}
        </Container>
      </Box>
    </>
  );
};

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(ProjectScreen);
