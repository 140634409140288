import ExcelJS from "exceljs";
import Excel from "node-xlsx";
import XLSX from "xlsx";

class Functions {
  static async sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static youtube(url) {
    var regex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regex);
    return match && match[7].length == 11 ? match[7] : false;
  }

  static xlsx(data, labels = {}) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Details", {});
    worksheet.properties.defaultRowHeight = 30;
    const columns = [];

    for (const [key, value] of Object.entries(labels)) {
      columns.push({
        header: value,
        key: key,
        width: 24,
      });
    }

    worksheet.columns = columns;

    data.map((item, row) => {
      worksheet.addRow({
        id: row,
        ...item,
      });
    });

    workbook.xlsx
      .writeBuffer({
        base64: true,
      })
      .then(function (xls64) {
        var a = document.createElement("a");
        var data = new Blob([xls64], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        var url = URL.createObjectURL(data);
        a.href = url;
        a.download = "export.xlsx";
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      })
      .catch(function (error) {
        console.error(error);
      });
  }
}

export default Functions;
