import React from "react";
import Text from "./Text";
import { Mobile } from "../../Bitter";
import { Colors } from "../../Helpers";

const Component = (props) => {
  return (
    <Mobile
      render={(mobile) => {
        const { rule = "Body" } = props;
        return rule === "Display" ? (
          <Text
            display="block"
            size={12}
            line={12.3}
            family="Lufga"
            weight="700"
            color={Colors.white}
            {...props}
          />
        ) : rule === "DisplayLarge" ? (
          <Text
            display="block"
            size={16}
            family="Lufga"
            weight="700"
            color={Colors.white}
            {...props}
          />
        ) : rule === "Content" ? (
          <Text
            display="block"
            size={13}
            line={13.33}
            family="Lufga"
            weight="500"
            color="rgba(0, 0, 0, 0.5)"
            {...props}
          />
        ) : rule === "Title" ? (
          <Text
            display="block"
            size={12}
            line={14.4}
            family="Lufga"
            weight="400"
            color="rgba(0, 0, 0, 0.5)"
            {...props}
          />
        ) : rule === "Categories" ? (
          <Text
            display="block"
            size={16}
            line={16.4}
            family="Lufga"
            weight="600"
            color={Colors.black}
            {...props}
          />
        ) : rule === "FooterTitle" ? (
          <Text
            display="block"
            size={13}
            line={12.3}
            family="Lufga"
            weight="700"
            color={Colors.black}
            {...props}
          />
        ) : rule === "Button" ? (
          <Text
            display="block"
            size={11}
            line={11.27}
            family="Lufga"
            weight="700"
            color="rgba(240, 0, 0, 0.5)"
            {...props}
          />
        ) : rule === "Project" ? (
          <Text
            display="block"
            size={24}
            line={24.6}
            family="Lufga"
            weight={700}
            color={Colors.black}
            {...props}
          />
        ) : rule === "Small" ? (
          <Text
            display="block"
            size={9}
            line={12}
            family="Lufga"
            weight="400"
            color="rgba(0, 0, 0, 0.5)"
            {...props}
          />
        ) : rule === "Red" ? (
          <Text
            display="block"
            size={12}
            line={12}
            family="Lufga"
            weight={700}
            color={Colors.primary}
            {...props}
          />
        ) : rule === "Description" ? (
          <Text
            display="block"
            size={11}
            line={12}
            family="Lufga"
            weight="400"
            color="#707070"
            {...props}
          />
        ) : rule === "BigWhite" ? (
          <Text
            family="Lufga"
            display="block"
            size={36}
            line={36.9}
            weight={700}
            color={Colors.white}
            {...props}
          />
        ) : rule === "SmallWhite" ? (
          <Text
            family="Lufga"
            display="block"
            size={24}
            line={24.6}
            weight={500}
            color={Colors.white}
            opacity={0.75}
            {...props}
          />
        ) : rule === "SmallRule" ? (
          <Text
            family="Lufga"
            display="block"
            size={16}
            line={19.2}
            weight={400}
            color={Colors.black}
            {...props}
          />
        ) : rule === "TitleRule" ? (
          <Text
            family="Lufga"
            display="block"
            size={20}
            line={24}
            weight={700}
            color={Colors.black}
            {...props}
          />
        ) : rule === "Epi5" ? (
          <Text
            family="Lufga"
            display="block"
            size={13}
            line={13.33}
            weight={500}
            color={Colors.black}
            {...props}
          />
        ) : rule === "Progress" ? (
          <Text
            family="Lufga"
            display="block"
            size={12}
            line={12}
            weight={700}
            color={Colors.white}
            {...props}
          />
        ) : rule === "Goal" ? (
          <Text
            display="block"
            size={16}
            line={16.4}
            family="Lufga"
            weight="600"
            color="rgba(0, 0, 0, 0.5)"
            {...props}
          />
        ) : rule === "BigBlack" ? (
          <Text
            family="Lufga"
            display="block"
            size={32}
            line={32.8}
            weight={700}
            color={Colors.black}
            {...props}
          />
        ) : rule === "Pome" ? (
          <Text
            family="Lufga"
            display="block"
            size={13}
            line={13.33}
            weight={600}
            color={Colors.pome}
            {...props}
          />
        ) : rule === "Order" ? (
          <Text
            family="Lufga"
            display="block"
            size={10}
            line={12}
            weight={700}
            color="rgba(0, 0, 0, 0.75)"
            {...props}
          />
        ) : rule === "Dark" ? (
          <Text
            family="Lufga"
            display="block"
            size={13}
            line={15.6}
            weight={300}
            color={Colors.darkgrey}
            {...props}
          />
        ) : rule === "PomeProgramme" ? (
          <Text
            family="Lufga"
            display="block"
            size={13}
            line={13.33}
            weight={600}
            color={Colors.pome}
            {...props}
          />
        ) : rule === "Payment" ? (
          <Text
            family="Lufga"
            display="block"
            size={24}
            line={28.8}
            weight={300}
            color="rgba(255, 255, 255, 0.7)"
            {...props}
          />
        ) : rule === "Login" ? (
          <Text
            family="Lufga"
            display="block"
            size={14}
            line={16.8}
            weight={700}
            color={Colors.black}
            {...props}
          />
        ) : (
          <Text
            family="Lufga"
            display="block"
            size={16}
            weight={400}
            color={Colors.black}
            {...props}
          />
        );
      }}
    />
  );
};

export default Component;
