import React, { useState } from "react";
import { Box, Icon, Rule, Mobile } from "../Bitter";

const Style = "--underline";

const ProfileContainer = ({ current = "pledged-projects" }) => {
  return (
    <Mobile
      render={(mobile) =>
        mobile ? null : (
          <Box
            height="100%"
            width="100%"
            display="flex"
            direction="column"
            top={50}
          >
            <Rule bottom={50} rule="BigBlack">
              Profile
            </Rule>
            <Box
              width="100%"
              style={{ border: "1px solid rgba(221, 221, 221, 1)" }}
              display="flex"
              direction="column"
              radius={8}
            >
              <Box mode="margin" top={25} left={25} bottom={30}>
                <a href="/profilepledged">
                  <Rule
                    rule="Project"
                    size={16}
                    line={16.4}
                    styling={current === "pledged-projects" ? Style : undefined}
                  >
                    Pledged Projects
                  </Rule>
                </a>
              </Box>
              <a href="/myprojects">
                <Rule
                  rule="Project"
                  size={16}
                  line={16.4}
                  mode="margin"
                  left={25}
                  bottom={30}
                  styling={current === "my-projects" ? Style : undefined}
                >
                  My Projects
                </Rule>
              </a>
              <a href="/payouts">
                <Rule
                  rule="Project"
                  size={16}
                  line={16.4}
                  mode="margin"
                  left={25}
                  bottom={30}
                  styling={current === "payouts" ? Style : undefined}
                >
                  Payouts
                </Rule>
              </a>
              {/*<a href="/analytics">
                <Rule
                  rule="Project"
                  size={16}
                  line={16.4}
                  mode="margin"
                  left={25}
                  bottom={30}
                  styling={current === "analytics" ? Style : undefined}
                >
                  Analytics
                </Rule>
              </a>*/}
              <Box display="flex">
                <Rule
                  rule="Project"
                  size={16}
                  line={16.4}
                  mode="margin"
                  left={25}
                  bottom={30}
                  styling={current === "update-profile" ? Style : undefined}
                >
                  Update Profile
                </Rule>
                <Icon left={10} size={16} icon="arrow-right" />
              </Box>
              <Box display="flex">
                <Rule
                  rule="Project"
                  size={16}
                  line={16.4}
                  mode="margin"
                  left={25}
                  bottom={30}
                  styling={current === "become-creator" ? Style : undefined}
                >
                  Become a Creator
                </Rule>
                <Icon left={10} size={16} icon="arrow-right" />
              </Box>
            </Box>
          </Box>
        )
      }
    />
  );
};

export default ProfileContainer;
