const Colors = {
  black: "#000000",
  white: "#FFFFFF",
  primary: "#F00000",
  grey: "#F7F7F7",
  text: "#707070",
  pome: "#F77373",
  darkgrey: "#8A8A8A",
  pinky: "#FDE5E5",
};

export default Colors;
