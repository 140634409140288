import React, { useEffect, useState } from "react";
import { Box, Rule, Icon } from "../../Bitter";
import { Colors, Functions } from "../../Helpers";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";
import { connect } from "react-redux";
import { API } from "../../Modules";
import Moment from "moment";

const BigProjectCard = ({ redux, core, project, carousel = false }) => {
  const { user } = redux;

  const [trailer, setTrailer] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const days = Moment(project?.endDate).diff(Moment(), "days");

  let status = "Progress";

  if (days <= 20) {
    status = "Almost Done";
  }

  let progress = parseInt(
    (project?.collected * 100) / project?.projectMilestone,
    10
  );

  const sponsored = project.sponsored || false;

  return (
    <>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={trailer}
        videoId={Functions.youtube(project?.trailer)}
        onClose={() => setTrailer(false)}
      />
      <Box display="flex" direction="column" width="100%" height="100%">
        <Box
          height={300}
          color="black"
          width="100%"
          radius={8}
          image={"url(" + project?.assets[imageIndex]?.access + ")"}
          position="relative"
          overflow="hidden"
        >
          {carousel ? (
            <Box
              mode="position"
              position="absolute"
              display="inline-flex"
              align="center"
              style={{
                right: "unset",
                bottom: 12,
                top: "unset",
                left: "50%",
                transform: "translateX(-50%)",
              }}
              layer={2}
            >
              {(() => {
                let bullets = [];
                for (var i = 0; i < project?.assets?.length; i++) {
                  bullets.push(1);
                }

                return bullets.map((b, bb) => (
                  <Box
                    left={4}
                    right={4}
                    radius={999}
                    color="#fff"
                    opacity={bb === imageIndex ? 1 : 0.5}
                    width={bb === imageIndex ? 10 : 8}
                    height={bb === imageIndex ? 10 : 8}
                    press={() => setImageIndex(bb)}
                  />
                ));
              })()}
            </Box>
          ) : undefined}
          <Box
            mode="position"
            position="absolute"
            styling="--overlay"
            style={{
              padding: 20,
              display: "flex",
              alignContent: "space-between",
              justifyContent: "space-between",
            }}
            width="100%"
            height="100%"
          >
            <Box display="inline-flex" align="flex-start">
              <Icon
                press={async () => {
                  const response = await API.get(
                    "/project/star/" + project._id,
                    localStorage.getItem("access_token")
                  );

                  if (response?.data?.stars && response?.data?.user) {
                    core("user", response.data.user);
                  }
                }}
                fill={
                  user?.stars?.includes(project?._id) ? Colors.white : undefined
                }
                icon="star"
                size={16}
                color={Colors.white}
                right={16}
              />
              <Icon
                press={async () => {
                  const response = await API.get(
                    "/project/like/" + project._id,
                    localStorage.getItem("access_token")
                  );

                  if (response?.data?.likes && response?.data?.user) {
                    core("user", response.data.user);
                  }
                }}
                fill={
                  user?.likes?.includes(project?._id) ? Colors.white : undefined
                }
                icon="thumbs-up"
                size={16}
                color={Colors.white}
                right={16}
              />
              <Icon
                press={async () => {
                  const response = await API.get(
                    "/project/dislike/" + project._id,
                    localStorage.getItem("access_token")
                  );

                  if (response?.data?.dislikes && response?.data?.user) {
                    core("user", response.data.user);
                  }
                }}
                fill={
                  user?.dislikes?.includes(project?._id)
                    ? Colors.white
                    : undefined
                }
                icon="thumbs-down"
                size={16}
                color={Colors.white}
              />
            </Box>
            {Functions.youtube(project?.trailer) ? (
              <Box
                display="inline-flex"
                align="flex-end"
                direction="column"
                justify="flex-end"
                press={() => setTrailer(true)}
              >
                <Rule size={9} color={Colors.white}>
                  watch the
                </Rule>
                <Box display="inline-flex" align="center" bottom={5}>
                  <Icon icon="film" size={16} color={Colors.white} right={5} />
                  <Rule size={12} color={Colors.white}>
                    Trailer
                  </Rule>
                </Box>
                <Box
                  width={90}
                  height={55}
                  color="rgba(0,0,0,0.5)"
                  radius={8}
                  display="inline-flex"
                  align="center"
                  justify="center"
                >
                  <Icon
                    icon="play"
                    size={16}
                    color={Colors.white}
                    fill={Colors.white}
                    right={5}
                  />
                </Box>
              </Box>
            ) : undefined}
          </Box>
        </Box>
        <Box
          top={15}
          display="flex"
          align="center"
          justify="space-between"
          width="100%"
        >
          <Box display="flex" align="center">
            <a href={"/p/" + project?._id}>
              <Rule
                rule="Project"
                display="block"
                style={{ whiteSpace: "nowrap" }}
              >
                {project?.name}
              </Rule>
            </a>
          </Box>

          <Box display="flex" align="center">
            <Box display="flex" direction="column">
              <Rule rule="Red">{progress}%</Rule>
            </Box>
            {sponsored ? (
              <Box
                display="flex"
                direction="column"
                left={15}
                justify="flex-end"
              >
                <Rule rule="Small">Sponsored By </Rule>
                <Box display="flex" align="center" justify="center">
                  <img
                    src="/assets/i/Icon.png"
                    style={{ height: "12px", width: "12px" }}
                  ></img>
                  <Rule
                    left={5}
                    line={12}
                    rule="FooterTitle"
                    color={Colors.black}
                    size={9}
                  >
                    theatredor
                  </Rule>
                </Box>
              </Box>
            ) : undefined}
          </Box>
        </Box>
        <Box bottom={30} display="flex" align="center" top={15}>
          <Rule rule="Description">{project?.description}</Rule>
        </Box>
      </Box>
    </>
  );
};

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(BigProjectCard);
