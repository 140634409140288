import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import {
  Box,
  ProjectCard,
  Rule,
  SmallProjectCardSkeleton,
  Mobile,
} from "../Bitter";
import {
  CategoriesContainer,
  FooterContainer,
  MenuContainer,
  ProfileContainer,
} from "../Containers";
import { Colors, Functions } from "../Helpers";
import { API } from "../Modules";
//
const MyProjects = () => {
  const [projects, setProjects] = useState([]);

  const getProjects = async (theatredor) => {
    const response = await API.get("/user/" + theatredor);
    if (response?.data?.user) {
      setProjects(response.data.user.projects);
    }
  };

  useEffect(() => {
    getProjects(48);
  }, []);
  return (
    <Mobile
      render={(mobile) => (
        <>
          <CategoriesContainer />
          <Container>
            <Box
              height="100%"
              width="100%"
              direction="column"
              flex={1}
              display="flex"
              color="#FFFFFF"
              bottom={50}
            >
              <Row>
                <Col sm={12} md={4} lg={3} xxl={4}>
                  <ProfileContainer current="my-projects" />
                </Col>
                <Col sm={12} md={8} lg={9} xxl={8}>
                  <Box>
                    {projects.length > 0 &&
                      projects.map((project) => (
                        <ProjectCard
                          project={project}
                          id={project._id}
                          key={project._id}
                          title={project.name}
                          description={project.description}
                          backers={project.backers.length}
                          goal={project.projectMilestone / 100}
                          fund={project.collected / 100}
                          image={project.assets[0]?.access}
                          currency={project.projectCurrency}
                          slug={project._id}
                          publicity={project.public ? "live" : "review"}
                          update={true}
                        />
                      ))}
                  </Box>
                </Col>
              </Row>
            </Box>
          </Container>
        </>
      )}
    />
  );
};

export default MyProjects;
