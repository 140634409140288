import React, { useState } from "react";
import { Box, Rule, Icon } from "../../Bitter";
import { Colors } from "../../Helpers";
import Moment from "moment";
import NumberFormat from "react-number-format";
import { API } from "../../Modules";
import { useStripe } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import { SuccessContainer } from "../../Containers";

const ProjectsDetails = ({ project = {}, redux }) => {
  const stripe = useStripe();

  const days = Moment(project?.endDate).diff(Moment(), "days");
  let progress = parseInt(
    (project?.collected * 100) / project?.projectMilestone,
    10
  );
  if (progress >= 100) {
    progress = 100;
  }

  let expired = days < -14 ? true : false;

  const date = Moment(project?.endDate).format("MMMM Do YYYY, h:mm:ss a");

  const total = project.collected / 100;
  const goal = project.projectMilestone / 100;
  const backers = project.backers.length;

  const [pledgeAmount, setPledgeAmount] = useState(10);
  const pledge = async () => {
    try {
      const response = await API.update(
        redux.user
          ? "/payment/create-session"
          : "/payment/create-unregistered-session",
        {
          amount: pledgeAmount * 100,
          currency: project?.projectCurrency,
          projectName: project?.name,
          projectId: project?._id,
        },
        localStorage.getItem("access_token")
      );

      if (response?.data?.session) {
        stripe.redirectToCheckout({ sessionId: response.data.session.id });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box display="flex" direction="column" justify="center">
      {project.creatorDetails ? (
        <Box display="flex" align="center">
          <Box
            height="48px"
            width="48px"
            image={"url(" + project.creatorDetails.image + ")"}
          ></Box>
          <Box display="flex" direction="column" left={10}>
            <Rule rule="Project" size={14} line={24}>
              {project.creatorDetails.producer}
            </Rule>
            <Rule rule="Content" size={14} line={24}>
              {project.creatorDetails.producerDescription}
            </Rule>
          </Box>
        </Box>
      ) : undefined}
      <Box radius={4} color="#F7F7F7" left={4} top={30} overflow="hidden">
        <Box
          style={{ minWidth: 24 }}
          left={4}
          width={
            parseInt(progress > 100 ? (progress = 100) : progress, 10) + "%"
          }
          height={16}
          color={Colors.primary}
          display="flex"
          align="center"
          mode="padding"
        >
          <Rule rule="Progress">{parseInt(progress, 10)}%</Rule>
        </Box>
      </Box>
      <Box top={24} display="flex" direction="column">
        <Rule rule="Project">
          {total} {project?.projectCurrency?.toUpperCase()}
        </Rule>
        <Rule top={5} rule="Goal">
          total pledged of goal {goal} {project?.projectCurrency?.toUpperCase()}
        </Rule>
      </Box>
      <Box top={24} display="flex" direction="column">
        <Rule rule="Project">{backers}</Rule>
        <Rule top={5} rule="Goal">
          total backers
        </Rule>
      </Box>

      {expired ? undefined : (
        <Box top={24} display="flex" direction="column">
          <Rule rule="Project">{days}</Rule>
          <Rule top={5} rule="Goal">
            days left to complete
          </Rule>
        </Box>
      )}

      {expired ? (
        <SuccessContainer success={false}></SuccessContainer>
      ) : (
        <Box display="flex" align="center" top={15}>
          <NumberFormat
            suffix={" " + project?.projectCurrency?.toUpperCase()}
            style={{
              height: 40,
              border: "2px solid #DDD",
              display: "block",
              maxWidth: 96,
              borderRadius: 8,
              paddingLeft: 8,
              paddingRight: 8,
              fontFamily: "Lufga",
              color: "black",
            }}
            value={pledgeAmount}
            onChange={(event) => {
              setPledgeAmount(parseInt(event.target.value, 10));
            }}
          />
          <Box
            flex={3}
            left={12}
            height={40}
            color={Colors.primary}
            display="flex"
            align="center"
            justify="center"
            radius={8}
            press={pledge}
          >
            <Rule size={16} line={16.4} rule="BigWhite">
              Back to Project
            </Rule>
          </Box>
        </Box>
      )}
      <Box display="flex" justify="flex-end" top={10}>
        <a
          href={
            "http://www.twitter.com/share?url=crowdfunding.theatredor.com/p/" +
            project?._id
          }
          target="_blank"
          rel="noopener"
        >
          <img
            src="/assets/i/twitter.png"
            style={{ height: 24, width: 24, marginLeft: 10 }}
          ></img>
        </a>
        <a
          href={
            "https://www.facebook.com/sharer/sharer.php?u=crowdfunding.theatredor.com/p/" +
            project?._id
          }
          target="_blank"
          rel="noopener"
        >
          <img
            src="/assets/i/facebook.png"
            style={{ height: 24, width: 24, marginLeft: 10 }}
          ></img>
        </a>
      </Box>
      {expired ? undefined : (
        <Box top={25}>
          <Rule rule="Small" size={12} line={16}>
            All or nothing. This project will only be funded if it reaches its
            goal by {date}.
          </Rule>
        </Box>
      )}
    </Box>
  );
};

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(ProjectsDetails);
