import Store from "./Store";
import { Functions } from "../Helpers";

class Auth {
  static available() {
    if (this.store().token) {
      return true;
    }
    return false;
  }

  static store() {
    return Store.getState();
  }

  static user() {
    if (this.available()) {
      return this.store().user;
    }
    return false;
  }

  static login(token, user) {
    Store.dispatch({ type: "CORE", key: "token", value: token });
    Store.dispatch({ type: "CORE", key: "user", value: user });
    return true;
  }

  static update(user) {
    Store.dispatch({ type: "CORE", key: "user", value: user });
    localStorage.setItem("user", JSON.stringify(user));
    return true;
  }

  static logout() {
    Store.dispatch({ type: "CORE", key: "token", value: false });
    Store.dispatch({ type: "CORE", key: "user", value: false });
    localStorage.clear();

    return true;
  }

  static token() {
    if (this.available()) {
      return this.store().token;
    }
    return false;
  }

  static bootstrap() {
    if (localStorage.getItem("access_token") && localStorage.getItem("user")) {
      Auth.login(
        localStorage.getItem("access_token"),
        JSON.parse(localStorage.getItem("user"))
      );
    }

    return true;
  }
}

export default Auth;
