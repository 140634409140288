import React from "react";
import { Box, Rule } from "../../Bitter";
import { Colors } from "../../Helpers";

const RULES = {
  small: "Display",
  medium: "None",
  large: "DisplayLarge",
};

const HEIGHTS = {
  small: 24,
  medium: 32,
  large: 48,
};

const Component = ({
  children,
  opacity = true,
  mode = "default",
  background = Colors.primary,
  disabled = false,
  press = undefined,
  size = "small",
  bottom = 0,
  to,
}) => {
  return (
    <Box
      left={14}
      display="inline-flex"
      align="center"
      justify="center"
      color={background}
      mode="padding"
      right={14}
      height={HEIGHTS[size]}
      radius={8}
      opacity={opacity ? 1 : 0.5}
      press={
        to
          ? () => {
              if (press) {
                press();
              }

              window.location = to;
            }
          : press
      }
      style={{ marginBottom: bottom }}
    >
      <Rule rule={RULES[size]} color="#FFFFFF">
        {children}
      </Rule>
    </Box>
  );
};

export default Component;
