import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, Rule, Mobile } from "../Bitter";
import { Resources } from "../Helpers";
import { API } from "../Modules";

const CategoriesContainer = ({ redux }) => {
  const { categories } = redux;

  return (
    <Mobile
      render={(mobile) =>
        mobile ? null : (
          <Box
            height={66}
            width="%100"
            display="flex"
            align="center"
            justify="center"
            image="linear-gradient(180deg, #F7F7F7 -100%, #FFFFFF 100%)"
          >
            <Box display="flex" align="center">
              {categories?.map((category) => (
                <Rule
                  key={category._id}
                  rule="Categories"
                  right={25}
                  to={"/c/" + category.slug}
                >
                  {category.name}
                </Rule>
              ))}
            </Box>
          </Box>
        )
      }
    />
  );
};

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(CategoriesContainer);
