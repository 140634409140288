import { createStore } from "redux";
import Hydrater from "./Hydrater";

const Loader = new Hydrater();

const STATE = {
  popup: false,
  token: false,
  user: false,
};

const Reducer = (state = STATE, action) => {
  var holder = { ...state };
  switch (action.type) {
    case "CORE":
      holder[action.key] = action.value;
      return holder;
    default:
      return holder;
  }
};

const Store = createStore(Reducer, Loader.load());

Store.subscribe(() => {
  Loader.save(Store.getState());
});

export default Store;
