import React from "react";
import "../Styles/bar.css";

const BarChart = ({
  min = 0,
  data,
  height = 150,
  width = "100%",
  align = "flex-end",
  color = "#F00000",
}) => {
  const calculate = (value) => {
    let calculated = 0;

    let max = 0;

    data.map((item) => {
      if (item.data > max) {
        max = item.data;
      }
    });

    calculated = (value * height) / max;

    if (calculated < min) {
      return min;
    }

    return calculated;
  };

  return (
    <div
      style={{
        height,
        width,
        display: "flex",
        alignItems: align,
        marginLeft: -6,
        marginRight: -6,
      }}
    >
      {data.map((item) => (
        <div
          style={{
            backgroundColor: color,
            height: calculate(item.data),
            marginRight: 6,
            marginLeft: 6,
            flex: 1,
          }}
          className="bitter-bar"
        >
          <div className="tooltip">
            {item.labels
              ? typeof item.labels === "object"
                ? item.labels.map((label) =>
                    typeof label === "object" ? (
                      <span style={{ ...label.style }}>{label.text}</span>
                    ) : (
                      <span>{label}</span>
                    )
                  )
                : item.labels
              : item.data}
          </div>
        </div>
      ))}
    </div>
  );
};

export default BarChart;
