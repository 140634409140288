import React, { useState, useEffect } from "react";

import { Box, Rule, Mobile } from "../";
import { Colors, Functions } from "../../Helpers";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";

import Moment from "moment";

const Input = (props) => {
  const {
    placeholder = "",
    name = "",
    wide = false,
    active = true,
    value = "",
    change = false,
    options = [],
    disabled = false,
    type,
  } = props;

  const [open, set] = useState(false);
  const [rendered, _rendered] = useState(true);

  useEffect(async () => {
    _rendered(false);
    await Functions.sleep(0);
    _rendered(true);
  }, [open]);

  if (!rendered) {
    return null;
  }

  return (
    <Box display="flex" direction="column">
      <Box bottom={5}>
        <Rule
          style={{ opacity: disabled && 0.5 }}
          rule="TitleRule"
          size={14}
          line={16.8}
        >
          {name}
        </Rule>
      </Box>
      <Mobile
        render={(mobile) =>
          mobile ? (
            <LocalizationProvider dateAdapter={AdapterDateFns} localeText="en">
              <MobileDatePicker
                hideTabs={true}
                showToolbar={false}
                inputFormat="yyyy-MM-dd"
                value={value}
                onChange={(e) => {
                  set(false);
                  if (disabled) {
                    return;
                  }
                  if (change && !disabled) {
                    change(Moment(e).format("YYYY-MM-DD"));
                  }
                }}
                open={open}
                closeOnSelect={true}
                renderInput={(params) => {
                  return (
                    <>
                      <Box
                        height={wide ? "100px" : "40px"}
                        width="100%"
                        align="center"
                        radius={8}
                        style={{ border: "1px solid #DDDDDD" }}
                      >
                        <input
                          ref={params.inputRef}
                          style={{
                            background: Colors.gray,
                            fontFamily: "Lufga",
                            fontWeight: "400",
                            fontSize: "14px",
                            paddingLeft: "12px",
                            paddingTop: "11px",
                            paddingBottom: "12px",
                            width: "100%",
                            opacity: disabled && 0.5,
                          }}
                          {...params.inputProps}
                          onFocus={() => set(true)}
                          placeholder={placeholder}
                        ></input>
                      </Box>
                    </>
                  );
                }}
              />
            </LocalizationProvider>
          ) : (
            <LocalizationProvider dateAdapter={AdapterDateFns} localeText="en">
              <DesktopDatePicker
                closeOnSelect={true}
                inputFormat="yyyy-MM-dd"
                value={value}
                onChange={(e) => {
                  set(false);
                  if (disabled) {
                    return;
                  }
                  if (change && !disabled) {
                    change(Moment(e).format("YYYY-MM-DD"));
                  }
                }}
                open={open}
                renderInput={(params) => {
                  return (
                    <>
                      <Box
                        height={wide ? "100px" : "40px"}
                        width="100%"
                        align="center"
                        radius={8}
                        style={{ border: "1px solid #DDDDDD" }}
                      >
                        <input
                          ref={params.inputRef}
                          style={{
                            background: Colors.gray,
                            fontFamily: "Lufga",
                            fontWeight: "400",
                            fontSize: "14px",
                            paddingLeft: "12px",
                            paddingTop: "11px",
                            paddingBottom: "12px",
                            width: "100%",
                            opacity: disabled && 0.5,
                          }}
                          {...params.inputProps}
                          onFocus={() => set(true)}
                          placeholder={placeholder}
                        ></input>
                      </Box>
                    </>
                  );
                }}
              />
            </LocalizationProvider>
          )
        }
      />
    </Box>
  );
};

export default Input;
