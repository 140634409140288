import React from "react";
import ContentLoader from "react-content-loader";
import { Container, Row } from "react-grid-system";

const SmallProjectCardSkeleton = (props) => {
  return (
    <ContentLoader
      viewBox="0 0 500 144"
      width="100%"
      height={144}
      speed={2}
      {...props}
    >
      <rect x="60.84" y="9.93" rx="5" ry="5" width="120.55" height="70.59" />
      <rect
        x="192.84"
        y="9.67"
        rx="5"
        ry="5"
        width="calc(100% - 192.84px)"
        height="17"
      />
      <rect x="192.84" y="31.67" rx="5" ry="5" width="50.75" height="17" />
      <rect x="248.84" y="31.67" rx="5" ry="5" width="50.75" height="17" />
      <rect
        x="192.84"
        y="51.67"
        rx="5"
        ry="5"
        width="calc(100% - 192.84px)"
        height="12"
      />
      <rect x="192.84" y="66.67" rx="5" ry="5" width="120.72" height="12" />
      <rect x="192.84" y="81.67" rx="5" ry="5" width="148.72" height="12" />
      <rect x="192.84" y="96.67" rx="5" ry="5" width="120.72" height="12" />
    </ContentLoader>
  );
};

export default SmallProjectCardSkeleton;
