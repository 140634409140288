import React, { useEffect, useState } from "react";
import { Box } from "../Bitter";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { API } from "../Modules";

const SuccessScreen = () => {
  const { search } = useLocation();
  const { session_id } = queryString.parse(search);
  const [paymentInformation, setPaymentInformation] = useState({
    session: null,
    customer: null,
  });
  const getPaymentInformation = async () => {
    // this will return session and customer information
    const response = await API.get(
      `/payment/payment-success/${session_id}`,
      localStorage.getItem("access_token")
    );
    if (response?.data?.session && response?.data?.customer) {
      setPaymentInformation({
        session: response.data.session,
        customer: response.data.customer,
      });
    }
  };
  useEffect(() => {
    getPaymentInformation();
  }, []);
  return (
    <Box>
      <h1>Thanks for your order, {paymentInformation?.customer?.name}</h1>
    </Box>
  );
};

export default SuccessScreen;
