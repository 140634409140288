import React from "react";
import { Col, Container, Row } from "react-grid-system";
import {
  BigProjectCard,
  BigSkeleton,
  Box,
  ProjectCard,
  SmallProjectCardSkeleton,
  SubCategory,
  Rule,
  Button,
} from "../Bitter";
import { Resources } from "../Helpers";
import { CategoriesContainer, MenuContainer } from "../Containers";
import Lodash from "lodash";
import { API } from "../Modules";

class CategoryScreen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      category: null,
      projects: [],
      loading: true,
    };
  }

  async componentDidMount() {
    const response = await API.get(
      "/category/" + this.props.match.params.category
    );
    this.setState({ loading: false });
    if (response?.data?.category) {
      this.setState({ category: response.data.category });
      const projects = await this.getProjects();
      this.setState({ projects });
    }
  }

  getProjects = async () => {
    try {
      const response = await API.get(
        "/project/listview?category=" + this.state?.category?._id
      );
      if (response?.data?.projects) {
        return response.data.projects;
      }
    } catch (err) {
      return [];
    }
  };

  render() {
    const { category, projects, loading = true } = this.state;
    return (
      <>
        <CategoriesContainer />
        {category !== null && (
          <Container>
            <Box
              height="100%"
              width="100%"
              direction="column"
              flex={1}
              display="flex"
              color="#FFFFFF"
            >
              <SubCategory
                category={this.props.match.params.category}
                title={category.name}
                description={category.description}
                links={category.links}
                slug={category.slug}
              />
              <Box display="flex" top={50}>
                <Row style={{ width: "100%" }}>
                  <Col sm={12} md={7} lg={7} xxl={7}>
                    <Box width="100%">
                      {projects?.length > 0 ? (
                        projects.map((project, index) =>
                          index === 0 ? (
                            <BigProjectCard
                              project={project}
                              key={project._id}
                              id={project._id}
                              title={project.name}
                              image={project.assets[0]?.access}
                              description={project.description}
                            ></BigProjectCard>
                          ) : null
                        )
                      ) : loading ? (
                        <BigSkeleton />
                      ) : (
                        <Box
                          width="100%"
                          align="center"
                          justify="center"
                          direction="column"
                          display="flex"
                          identifier="big-project-card"
                        >
                          <img
                            src="/assets/i/404.svg"
                            className="--block --margin --restriction"
                          />
                          <Box width="100%" style={{ maxWidth: 500 }}>
                            <Rule
                              display="block"
                              rule="Project"
                              size={32}
                              line={32.8}
                            >
                              Create Project
                            </Rule>
                            <Rule
                              display="block"
                              rule="SmallRule"
                              top={20}
                              bottom={20}
                            >
                              Be the first one to start a project in{" "}
                              {category.name}. To create a project click on the
                              button down below.
                            </Rule>
                            <a href="/personalverification">
                              <Button>Start a Project</Button>
                            </a>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Col>
                  <Col sm={12} md={5} lg={5} xxl={5}>
                    {projects?.length > 0 ? (
                      projects.map((project, index) =>
                        index === 0 ? null : (
                          <ProjectCard
                            key={project._id}
                            project={project}
                            id={project._id}
                            title={project.name}
                            description={project.description}
                            backers={project.backers.length}
                            goal={project.projectMilestone / 100}
                            fund={project.collected / 100}
                            image={project.assets[0]?.access}
                            currency={project.projectCurrency}
                          />
                        )
                      )
                    ) : (
                      <>
                        <SmallProjectCardSkeleton />
                        <SmallProjectCardSkeleton />
                        <SmallProjectCardSkeleton />
                        <SmallProjectCardSkeleton />
                      </>
                    )}
                  </Col>
                </Row>
              </Box>
            </Box>
          </Container>
        )}
      </>
    );
  }
}

export default CategoryScreen;
