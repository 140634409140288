import Lodash from "lodash";

class Hydrater {
  load(original) {
    try {
      let state = sessionStorage.getItem(":state");

      if (state === null) {
        return original;
      }

      return JSON.parse(state);
    } catch (error) {
      return original;
    }
  }

  save(original) {
    try {
      let __original = Lodash.clone(original);

      __original.modal = false;

      let state = JSON.stringify(__original);
      sessionStorage.setItem(":state", state);
    } catch (error) {}
  }
}

export default Hydrater;
