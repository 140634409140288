import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-grid-system";

import { Box, Rule, Icon } from "../../Bitter";
import { Colors, Functions } from "../../Helpers";
import { API } from "../../Modules";
import { Link } from "react-router-dom";

const SubCategory = ({ category, title, description, links = [], slug }) => {
  return (
    <Box
      display="flex"
      direction="column"
      width="100%"
      height="100%"
      justify-content="left"
    >
      <Rule display="block" rule="Project" size={32} line={32.8}>
        {title}
      </Rule>
      <Rule display="block" rule="SmallRule" top={20}>
        {description}
      </Rule>
      {links.length > 0 ? (
        <Box top={20} display="flex" align="center">
          {links.map((subcategory) => (
            <Rule
              key={subcategory._id}
              rule="SmallRule"
              right={20}
              color={Colors.primary}
              style={{ textDecoration: "underline" }}
              to={"/c/" + slug + "/" + subcategory.slug}
            >
              {subcategory.name}
            </Rule>
          ))}
        </Box>
      ) : undefined}
    </Box>
  );
};

export default SubCategory;
