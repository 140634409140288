import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import { connect } from "react-redux";
import { Box, ProjectCard, Rule, SmallProjectCardSkeleton, Mobile } from "../Bitter";
import {
  CategoriesContainer,
  FooterContainer,
  MenuContainer,
  ProfileContainer,
} from "../Containers";
import { Colors } from "../Helpers";
import { API } from "../Modules";

const ProfilePledgedScreen = ({ redux }) => {
  const { user } = redux;
  Array.prototype.inArray = function (comparer) {
    for (var i = 0; i < this.length; i++) {
      if (comparer(this[i])) return true;
    }
    return false;
  };

  Array.prototype.pushIfNotExist = function (element, comparer) {
    if (!this.inArray(comparer)) {
      this.push(element);
    }
  };

  const [pledgedProjects, setPlegedProjects] = useState([]);

  const getPledgedProjects = async (theatredor) => {
    const response = await API.get("/user/" + theatredor);
    if (response?.data?.user) {
      const pledged = [];
      response.data.user.donations.map((donation) => {
        pledged.pushIfNotExist(
          donation.project,
          (item) => item._id === donation.project._id
        );
      });
      setPlegedProjects(pledged);
    }
  };

  useEffect(() => {
    if (user?.theatredor) {
      getPledgedProjects(user?.theatredor);
    }
  }, [user]);
  return (
    <Mobile
      render={(mobile) => (
        <>
          <CategoriesContainer />
          <Container>
            <Box
              height="100%"
              width="100%"
              direction="column"
              flex={1}
              display="flex"
              color="#FFFFFF"
              bottom={50}
            >
              <Row>
                <Col sm={12} md={4} lg={3} xxl={4}>
                  <ProfileContainer />
                </Col>
                <Col sm={12} md={8} lg={9} xxl={8}>
                  <Box>
                    <Box
                      top={mobile ? 0 : 133}
                      radius={8}
                      width="100%"
                      style={{ border: "1px solid rgba(221, 221, 221, 1)" }}
                    >
                      <Rule
                        rule="TitleRule"
                        size={13}
                        line={15.6}
                        style={{
                          paddingLeft: "20px",
                          paddingTop: "20px",
                          paddingRight: "20px",
                          paddingBottom: "20px",
                        }}
                      >
                        If you have any questions or requests regarding your
                        pledges. Please contact{" "}
                        <Rule
                          rule="TitleRule"
                          size={13}
                          line={15.6}
                          color={Colors.primary}
                          style={{
                            textDecoration: "underline",
                            display: "inline",
                          }}
                        >
                          support@theatredor.com
                        </Rule>{" "}
                        with your order number. You may request refunds in{" "}
                        <Rule
                          rule="TitleRule"
                          size={13}
                          line={15.6}
                          style={{
                            textDecoration: "underline",
                            display: "inline",
                          }}
                        >
                          30 days
                        </Rule>{" "}
                        after you have pledged to a project. In any case the
                        project is cancelled it will be refunded back you you in{" "}
                        <Rule
                          rule="TitleRule"
                          size={13}
                          line={15.6}
                          style={{
                            textDecoration: "underline",
                            display: "inline",
                          }}
                        >
                          30 business days
                        </Rule>{" "}
                        after the cancellation.
                      </Rule>
                    </Box>
                    {pledgedProjects.length > 0 &&
                      pledgedProjects.map((project) => (
                        <ProjectCard
                          project={project}
                          key={project._id}
                          id={project._id}
                          title={project.name}
                          description={project.description}
                          backers={project.backers.length}
                          goal={project.projectMilestone / 100}
                          fund={project.collected / 100}
                          image={project.assets[0]?.access}
                          currency={project.projectCurrency}
                        />
                      ))}
                  </Box>
                </Col>
              </Row>
            </Box>
          </Container>
        </>
      )}
    />
  );
};

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(ProfilePledgedScreen);
