import React, { useState } from "react";
import { connect } from "react-redux";
import { Box, Button, Input, Rule, Mobile } from "../Bitter";
import { Colors } from "../Helpers";
import { API, Auth } from "../Modules";
import Toast from "react-hot-toast";

const LoginPopup = ({ core, close }) => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const login = async (e) => {
    const response = await API.update(
      "/auth/login",
      formData,
      "",
      "post",
      false
    );
    console.log(response);
    if (response?.error) {
      if (response?.more) {
        console.log({more: response.more});
        Toast.error(response.more.response.data.message);
      }
    } else if (response?.data?.user && response?.data?.accessToken) {
      localStorage.setItem("access_token", response.data.accessToken);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      Auth.login(response.data.accessToken, response.data.user);
      core("user", response.data.user);
      close();
    }
  };

  return (
    <Mobile
      render={(mobile) => (
        <Box
          color={Colors.white}
          width={mobile ? "auto" : 321}
          height={mobile ? "auto" : 248}
          display="flex"
          direction="column"
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
            paddingBottom: mobile ? 20 : null,
            boxShadow: "0px 1px 2px 0px rgba(16, 17, 21, 0.15)",
          }}
          layer={999}
          radius={8}
        >
          <Input
            value={formData.username}
            change={(username) => setFormData({ ...formData, username })}
            name="Username"
            placeholder="username"
          ></Input>
          <Box top={15}>
            <Input
              value={formData.password}
              change={(password) => setFormData({ ...formData, password })}
              name="Password"
              type="password"
              placeholder="*******"
            ></Input>
          </Box>
          <Box display="flex" top={15}>
            {formData.username.length === 0 ||
            formData.password.length === 0 ? (
              <Button disabled={true} opacity={false}>
                Login
              </Button>
            ) : (
              <Button press={login} opacity={true}>
                Login
              </Button>
            )}

            <Rule left={15} rule="Epi5">
              Don’t have an account?{" "}
              <a
                href="https://theatredor.com/wp-login.php?redirect_to=https%3A%2F%2Flocalhost:3000"
                target="_blank"
              >
                <Rule
                  rule="Epi5"
                  style={{
                    textDecoration: "underline",
                    display: "inline",
                  }}
                >
                  Click here to register.
                </Rule>
              </a>
            </Rule>
          </Box>
          <a
            href="https://theatredor.com/wp-login.php?action=lostpassword"
            target="_blank"
          >
            <Box top={17}>
              <Rule
                rule="Red"
                size={13}
                line={13.33}
                weight={600}
                style={{
                  textDecoration: "underline",
                  display: "inline",
                }}
              >
                Forgot you password?
              </Rule>
            </Box>
          </a>
        </Box>
      )}
    />
  );
};

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(LoginPopup);
