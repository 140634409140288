import React from "react";
import { Box, Icon, Rule } from "../Bitter";

const SuccessContainer = ({ success = true }) => {
  console.log(success);
  return (
    <Box width="100%" display="flex" direction="column">
      <Box
        align="center"
        width="100%"
        display="flex"
        mode="padding"
        top={20}
        bottom={12}
      >
        <Icon
          icon={success ? "check" : "x"}
          color={success ? "#0CAF3A" : "#F00000"}
        ></Icon>

        <Rule left={15} rule="TitleRule" size={16} line={16}>
          {success ? "Project Completed!" : "Project Unsuccessful"}
        </Rule>
      </Box>
      <Rule rule="SmallRule" size={14} line={16}>
        {success
          ? "This project has been successfully funded at 26 August, 2022 by 26 backers. Make sure to follow the creator to redeem your rewards."
          : "This project couldn’t reach its goal till 26 August, 2022. Your donations will be refunded to your account."}
      </Rule>
    </Box>
  );
};

export default SuccessContainer;
