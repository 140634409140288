import React from "react";
import ReactConfetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import { Box, Rule, BarChart, DoughnutChart, Icon } from "../";
import { Colors } from "../../Helpers";
const PaymentPopup = ({ session, customer, project, press }) => {
  const { width, height } = useWindowSize();
  return (
    <Box
      layer={999}
      position="fixed"
      color="rgba(0,0,0,0.5)"
      width="100%"
      display="flex"
      justify="center"
      align="center"
      direction="column"
      top={0}
      left={0}
      mode="position"
      style={{ textAlign: "center", height: "100vh" }}
    >
      <ReactConfetti
        width={width}
        height={height}
        numberOfPieces={500}
        initialVelocityY={5}
      />
      <Box
        display="flex"
        align="center"
        justify="center"
        height={120}
        radius={999}
        color={Colors.primary}
      >
        <img
          src="/assets/i/check.png"
          style={{
            height: 55,
            width: 74,
            paddingLeft: 23,
            paddingRight: 23,
            paddingTop: 32,
            paddingBottom: 32,
          }}
        ></img>
      </Box>
      <Rule top={25} rule="Progress" size={48} line={57.6} color={Colors.white}>
        Payment Complete
      </Rule>
      <Box
        display="flex"
        direction="column"
        align="center"
        style={{
          maxWidth: "1000px",
          textAlign: "center",
        }}
      >
        <Rule top={25} rule="Payment" size={24} line={28.8}>
          Thank you for you contributions towards{" "}
          <Rule
            rule="SmallRule"
            color={Colors.white}
            size={24}
            line={28.8}
            style={{
              display: "inline",
              opacity: 1,
            }}
          >
            {project?.name}
          </Rule>{" "}
          by{" "}
          <Rule
            rule="SmallRule"
            color={Colors.white}
            size={24}
            line={28.8}
            style={{
              display: "inline",
              opacity: 1,
            }}
          >
            {project?.creator?.name + " " + project?.creator?.surname}
          </Rule>{" "}
        </Rule>
        <Rule rule="Payment" size={24} line={28.8}>
          You will get your payment details and invoice to you email{" "}
          <Rule
            rule="SmallRule"
            color={Colors.white}
            size={24}
            line={28.8}
            style={{
              display: "inline",
              opacity: 1,
            }}
          >
            {customer?.email}
          </Rule>
        </Rule>
        <Box
          top={25}
          radius={8}
          height={40}
          width={85}
          color={Colors.primary}
          display="flex"
          align="center"
          justify="center"
          press={press}
        >
          <Rule rule="Display" size={16} line={16.4}>
            Okay!
          </Rule>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentPopup;
