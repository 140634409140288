import React, { useState, useEffect } from "react";
import { Box, Rule } from "../Bitter";
import { Colors } from "../Helpers";

const CookiePopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("COOKIE_ACCEPTED") !== "yes") {
      setIsOpen(true);
    }
  }, []);

  return isOpen ? (
    <Box
      position="fixed"
      mode="position"
      width={320}
      color={Colors.white}
      layer={3}
      right={50}
      bottom={50}
      top="unset"
      left="unset"
      radius={8}
      style={{
        boxShadow: "0px 1px 2px 0px rgba(16, 17, 21, 0.15)",
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <Box display="flex" direction="column">
        <img
          src="/assets/i/cookie.png"
          style={{
            width: "15px",
            heigth: "15px",
            marginTop: "27px",
            marginBottom: "17px",
          }}
        />
        <Rule rule="Project" size={13} line={13.33}>
          Our Site Uses Cookies
        </Rule>
        <Rule rule="Epi5" top={15} bottom={21}>
          By using our site you agree on our{" "}
          <Rule
            rule="Epi5"
            color={Colors.black}
            style={{
              textDecoration: "underline",
              display: "inline",
            }}
            target="_blank"
            to="https://theatredor.com/cookie-policy"
          >
            Cookie Policy
          </Rule>
          . Click
          <Rule
            rule="Epi5"
            color={Colors.black}
            style={{
              textDecoration: "underline",
              display: "inline",
            }}
            target="_blank"
            to="https://theatredor.com/cookie-policy"
          >
            here
          </Rule>{" "}
          to learn more.
        </Rule>
        <Box
          display="flex"
          align="center"
          justify="center"
          width={65}
          height={24}
          color={Colors.primary}
          radius={8}
          bottom={20}
          press={() => {
            setIsOpen(false);

            localStorage.setItem("COOKIE_ACCEPTED", "yes");
          }}
        >
          <Rule rule="BigWhite" size={12} line={12.3}>
            Accept
          </Rule>
        </Box>
      </Box>
    </Box>
  ) : null;
};

export default CookiePopup;
