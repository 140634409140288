import React, { useEffect, useState } from "react";
import { Container } from "react-grid-system";
import {
  Box,
  Content,
  Icon,
  Input,
  Rule,
  ProjectDetailsEditor,
  Date as DateInput
} from "../Bitter";
import {
  CategoriesContainer,
  FooterContainer,
  MenuContainer,
} from "../Containers";
import { Colors } from "../Helpers";
import { useParams } from "react-router-dom";
import { API } from "../Modules";
import moment from "moment";
import Toast from "react-hot-toast";

const UpdateProjectScreen = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState(null);
  const [images, setImages] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);

  const getProjectInformation = async (id) => {
    const response = await API.get(`/project/${id}`);
    if (response?.data?.project) {
      setFormData({
        name: response.data.project.name,
        description: response.data.project.description,
        trailer: response.data.project.trailer,
        content: response.data.project.content,
        tags: response.data.project.tags,
        rewardTags: response.data.project.rewardTags,
        startDate: response.data.project.startDate,
        endDate: moment(response.data.project.endDate).format("YYYY-MM-DD"),
        canExtendEndDate: response.data.project.canExtendEndDate,
        category: response.data.project.category,
        subcategory: response.data.project.subcategory,
        assets: response.data.project.assets,
        projectMilestone: response.data.project.projectMilestone / 100,
        projectCurrency: response.data.project.projectCurrency,
        assetsToBeDeleted: [],
        media: null,
      });
      const uploadedAssets = response.data.project.assets.map((asset) => {
        return { id: asset._id, access: asset.access };
      });
      setImageURLs(uploadedAssets);
    }
  };

  const send = async () => {
    const projectFormData = new FormData();
    projectFormData.append("name", formData.name);
    projectFormData.append("description", formData.description);
    projectFormData.append("trailer", formData.trailer);
    projectFormData.append("content", JSON.stringify(formData.content));
    projectFormData.append("tags", formData.tags);
    projectFormData.append("rewardTags", formData.rewardTags);
    projectFormData.append("assetsToBeDeleted", formData.assetsToBeDeleted);
    projectFormData.append("endDate", formData.endDate);
    images.forEach((image) => {
      projectFormData.append("media", image);
    });

    console.log(formData.content, projectFormData.getAll("content"));

    const response = await API.update(
      "/project/update/" + id,
      projectFormData,
      localStorage.getItem("access_token"),
      "put"
    );

    if (response?.data?.updatedProject) {
      Toast((t) => response?.data.message);
      getProjectInformation(id);
    }
  };

  useEffect(() => {
    getProjectInformation(id);
  }, []);

  return (
    <>
      <CategoriesContainer />
      <Container>
        <Box height="100%" width="100%" display="flex" justify="center">
          {formData !== null && (
            <Box
              direction="column"
              flex={1}
              display="flex"
              color="#FFFFFF"
              align="center"
              justify="center"
            >
              <Box top={50} width="100%" style={{ maxWidth: "730px" }}>
                <Box display="flex">
                  <Rule rule="Project" size={32} line={32.8}>
                    Update a Project
                  </Rule>
                </Box>
                <Box top={50}>
                  <Box bottom={15}>
                    <Input
                      value={formData.name}
                      change={(name) => setFormData({ ...formData, name })}
                      name="Project Title"
                    ></Input>
                  </Box>

                  <Box bottom={15}>
                    <Input
                      value={formData.description}
                      change={(description) =>
                        setFormData({ ...formData, description })
                      }
                      name="Project Description"
                      wide={true}
                    ></Input>
                  </Box>
                  <Box bottom={15}>
                    <Input
                      value={formData.trailer}
                      change={(trailer) =>
                        setFormData({ ...formData, trailer })
                      }
                      name="Trailer Link"
                      placeholder="Link to your trailer"
                    ></Input>
                  </Box>
                  <Rule rule="TitleRule" size={14} line={16.8}>
                    Attachments
                  </Rule>
                  <Rule top={5} rule="Title" size={14} line={16.8}>
                    Images will be displayed inside a media slider and the first
                    video will be used as a trailer for the project.
                  </Rule>
                  <Box radius={8} styling="image-upload" display="flex">
                    <input
                      id="file-input"
                      multiple
                      accept="image/*"
                      type="file"
                      onChange={(e) => {
                        const files = Array.from(e.target.files);
                        setImages((oldArray) => [...oldArray, ...files]);
                        files.forEach((file) => {
                          setImageURLs((oldArray) => [
                            ...oldArray,
                            { file, access: URL.createObjectURL(file) },
                          ]);
                        });
                      }}
                    />
                    {imageURLs.map((imageSrc) => (
                      <Box
                        key={imageSrc.access}
                        top={3}
                        position="relative"
                        display="flex"
                        left={5}
                      >
                        <img
                          src={imageSrc.access}
                          style={{
                            height: "48px",
                            borderRadius: 8,
                            width: "48px",
                          }}
                        ></img>
                        <Icon
                          icon="x"
                          color={Colors.white}
                          size={12}
                          position="absolute"
                          style={{ right: 5, top: 2 }}
                          stroke={4}
                          press={(e) => {
                            if (imageSrc?.id) {
                              setFormData({
                                ...formData,
                                assetsToBeDeleted: [
                                  ...formData.assetsToBeDeleted,
                                  imageSrc?.id,
                                ],
                              });
                              setImageURLs(
                                imageURLs.filter(
                                  (url) => url?.id !== imageSrc?.id
                                )
                              );
                            } else {
                              setImages(
                                images.filter(
                                  (image) => image !== imageSrc?.file
                                )
                              );
                              setImageURLs(
                                imageURLs.filter(
                                  (url) => url.access !== imageSrc.access
                                )
                              );
                            }
                          }}
                        />
                      </Box>
                    ))}
                    <label
                      for="file-input"
                      style={{ padding: 17, marginLeft: 5, marginRight: 5 }}
                    >
                      <Icon icon="plus"></Icon>
                    </label>
                  </Box>
                  <Box top={15} bottom={15}>
                    <Input
                      disabled={true}
                      name="Start Date"
                      value={moment(formData.startDate).format("YYYY-MM-DD")}
                    ></Input>
                  </Box>
                  <Box bottom={15}>
                    <DateInput
                      disabled={!formData.canExtendEndDate}
                      name="End Date"
                      value={formData.endDate}
                      change={(endDate) =>
                        setFormData({ ...formData, endDate })
                      }
                    ></DateInput>
                  </Box>
                  <Box display="flex">
                    <Box bottom={15} width="70%" right={30}>
                      <Input
                        disabled={true}
                        name="Project Milestone"
                        value={formData.projectMilestone}
                      ></Input>
                    </Box>
                    <Box bottom={15} width="30%">
                      <Input
                        disabled={true}
                        name="Currency"
                        value={formData.projectCurrency.toUpperCase()}
                      ></Input>
                    </Box>
                  </Box>
                  <Box top={50}>
                    <ProjectDetailsEditor
                      content={formData.content}
                      update={(content) => {
                        setFormData({ ...formData, content });
                      }}
                    />
                  </Box>
                  <Box top={50} bottom={15}>
                    <Input
                      disabled={true}
                      name="Category"
                      value={formData.category[0].name}
                    ></Input>
                  </Box>
                  <Box bottom={15}>
                    <Input
                      disabled={true}
                      name="Subcategory"
                      value={formData.subcategory[0].name}
                    ></Input>
                  </Box>
                </Box>

                <Box
                  top={50}
                  bottom={50}
                  display="flex"
                  align="center"
                  justify="center"
                  color={Colors.primary}
                  width={115}
                  height={40}
                  radius={8}
                >
                  <Rule press={send} rule="BigWhite" size={16} line={16.4}>
                    Update
                  </Rule>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    </>
  );
};

export default UpdateProjectScreen;
