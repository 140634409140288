import Axios from "axios";
import Store from "./Store";
import Toast from "react-hot-toast";
import { Resources } from "../Helpers";

class API {
  static async get(slug, token = "", announce = true) {
    const promise = new Promise((resolve) => {
      Axios.get(Resources.url + slug, {
        headers: { Authorization: "Bearer " + token },
      })
        .then((res) => {
          const data = res.data;

          if (data.error && announce) {
            Toast((t) => data.message);
          }

          console.log({
            url: Resources.url + slug,
            response: data,
          });

          resolve({
            error: data.error,
            message: data.message,
            data: data,
          });
        })
        .catch(() => {
          if (announce) {
            Toast(
              (t) =>
                "Oops! Our developers spilled some coffee on our servers. Please try again later."
            );
          }

          console.log({
            url: Resources.url + slug,
            response: {
              error: true,
              message:
                "Oops! Our developers spilled some coffee on our servers. Please try again later.",
              data: null,
            },
          });

          resolve({
            error: true,
            message:
              "Oops! Our developers spilled some coffee on our servers. Please try again later.",
            data: null,
          });
        });
    });
    const result = await promise;
    return result;
  }
  static async update(
    slug,
    request,
    token = "",
    mode = "post",
    announce = true
  ) {
    const admin = Store.getState().admin;
    let promise;
    mode === "post"
      ? (promise = new Promise((resolve) => {
          Axios.post(Resources.url + slug, request, {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
            .then((res) => {
              console.log(res);
              const data = res.data;

              if (data.error && announce) {
                Toast((t) => data.message);
              }

              console.log({
                url: Resources.url + slug,
                request,
                response: data,
              });

              resolve({
                //success: data.success,
                error: data.error,
                message: data.message,
                data: data,
              });
            })
            .catch((err) => {
              console.log({ err });
              console.log(err.message);
              if (announce) {
                Toast(
                  (t) =>
                    "Oops! Our developers spilled some coffee on our servers. Please try again later."
                );
              }

              console.log({
                url: Resources.url + slug,
                request,
                headers: {},
                response: {
                  success: false,
                  error: true,
                  message:
                    "Oops! Our developers spilled some coffee on our servers. Please try again later.",
                  data: null,
                },
                error: err,
              });

              resolve({
                success: false,
                error: true,
                message:
                  "Oops! Our developers spilled some coffee on our servers. Please try again later.",
                data: null,
                more: err,
              });
            });
        }))
      : (promise = new Promise((resolve) => {
          Axios.put(Resources.url + slug, request, {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
            .then((res) => {
              console.log(res);
              const data = res.data;

              if (data.error && announce) {
                Toast((t) => data.message);
              }

              console.log({
                url: Resources.url + slug,
                request,
                response: data,
              });

              resolve({
                //success: data.success,
                error: data.error,
                message: data.message,
                data: data,
              });
            })
            .catch((err) => {
              console.log(err.message);
              if (announce) {
                Toast(
                  (t) =>
                    "Oops! Our developers spilled some coffee on our servers. Please try again later."
                );
              }

              console.log({
                url: Resources.url + slug,
                request,
                headers: {},
                response: {
                  success: false,
                  error: true,
                  message:
                    "Oops! Our developers spilled some coffee on our servers. Please try again later.",
                  data: null,
                },
              });

              resolve({
                success: false,
                error: true,
                message:
                  "Oops! Our developers spilled some coffee on our servers. Please try again later.",
                data: null,
              });
            });
        }));
    const result = await promise;
    return result;
  }
  static async post(slug, request = {}, announce = true) {
    const admin = Store.getState().admin;

    const promise = new Promise((resolve) => {
      Axios.post(Resources.url + slug, request, {
        headers: {},
      })
        .then((res) => {
          console.log(res);
          const data = res.data;

          if (data.error && announce) {
            Toast((t) => data.error);
          }

          console.log({
            url: Resources.url + slug,
            request,
            response: data,
          });

          resolve({
            //success: data.success,
            error: data.error,
            message: data.message,
            data: data,
          });
        })
        .catch((err) => {
          if (announce) {
            Toast((t) => err);
          }

          console.log({
            url: Resources.url + slug,
            request,
            headers: {},
            response: {
              success: false,
              error: true,
              message:
                "Oops! Our developers spilled some coffee on our servers. Please try again later.",
              data: null,
            },
          });

          resolve({
            success: false,
            error: true,
            message:
              "Oops! Our developers spilled some coffee on our servers. Please try again later.",
            data: null,
          });
        });
    });
    const result = await promise;
    return result;
  }

  static async silent(slug, request = {}) {
    return await this.request(slug, request, false);
  }

  static source(uuid) {
    return Resources.url + "/picture/" + uuid + ".jpg";
  }
}

export default API;
