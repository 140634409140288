import React from "react";
import ContentLoader from "react-content-loader";

const BigSkeleton = (props) => {
  return (
    <ContentLoader
      width="100%"
      height={448}
      viewBox="0 0 900 580"
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
      {...props}
    >
      <rect x="0" y="304" rx="4" ry="4" width="119" height="30" />
      <rect x="0" y="345" rx="3" ry="3" width="calc(100%)" height="9" />
      <rect x="0" y="360" rx="3" ry="3" width="calc(100%)" height="9" />
      <rect x="0" y="-106" rx="10" ry="10" width="calc(100%)" height="400" />
    </ContentLoader>
  );
};

export default BigSkeleton;
