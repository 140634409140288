import React from "react";
import { Container } from "react-grid-system";
import { Box, Icon, Rule } from "../Bitter";
import {
  CategoriesContainer,
  FooterContainer,
  MenuContainer,
} from "../Containers";
import { Colors } from "../Helpers";

const OurRulesScreen = () => {
  return (
    <>
      <Box width="100%" height={212} color={Colors.primary}>
        <Box
          align="center"
          justify="center"
          display="flex"
          direction="column"
          style={{ textAlign: "center" }}
        >
          <Box style={{ maxWidth: "730px" }}>
            <Rule rule="BigWhite" top={50}>
              Our Rules
            </Rule>
            <Rule rule="SmallWhite" top={25}>
              Theatredor is a community of people committed to bringing art
              projects to life.
            </Rule>
          </Box>
        </Box>
      </Box>
      <Container>
        <Box
          height="100%"
          width="100%"
          direction="column"
          flex={1}
          display="flex"
          color="#FFFFFF"
          align="center"
          justify="center"
        >
          <Box top={50} style={{ maxWidth: "730px" }}>
            <Rule rule="SmallRule">
              We welcome and support projects from the following categories:
              Productions, Development, Education, Technical, Premises.
            </Rule>
            <Rule rule="SmallRule">
              Here are five rules every Theatredor project must follow.
            </Rule>
            <Box display="flex" top={25}>
              <Icon icon="check" color={Colors.primary} strokeWidth={3} />
              <Rule left={14} rule="TitleRule">
                Projects must create something to share with others
              </Rule>
            </Box>
            <Rule rule="SmallRule" top={25}>
              Theatredor can be used to create all sorts of projects that are
              linked to the performing arts: But every project needs a plan for
              creating something and sharing it with the world. At some point,
              the creator should be able to say: “It’s finished. Here’s what we
              created. Enjoy!” In other words, every project needs clearly
              identified aims that are demonstratable at the conclusion.
            </Rule>
            <Box display="flex" top={25}>
              <Icon icon="check" color={Colors.primary} strokeWidth={3} />
              <Rule left={14} rule="TitleRule">
                Projects and backer statistics must be honest and clearly
                presented.
              </Rule>
            </Box>
            <Box display="flex" top={25}>
              <Rule rule="SmallRule">
                Our community is built on trust and communication. Projects
                can’t mislead people or misrepresent facts. Creators should be
                candid about what they plan to accomplish and how they plan to
                do it. When a project involves complicated set design or
                construction, or the redevelopment of spaces or building for use
                in the performing arts we require projects to show backers
                detailed drawings or models of what they’re project would look
                like, and we prohibit the use of misleading imagery. Projects
                need to provide written proof that their plans comply with all
                UK law if asked for by Theatredor or backers. Creators should
                not misrepresent or artificially inflate the number of backers
                or amounts pledged to their projects.
              </Rule>
              <Rule rule="SmallRule">
                In regard to technical innovation involving lighting, sound, set
                construction, Flying, rigging stagecraft or special effects,
                prototype demonstration should reflect a product’s current state
                and should not include any CGI or special effects to demonstrate
                functionality that does not yet exist. If a project requires
                software and hardware integration, creators are required to show
                that functionality and any dependency clearly, or disclose that
                it has not yet been developed. Misleading imagery includes
                photorealistic renderings and heavily edited or manipulated
                images or videos that could give backers a false impression of a
                product’s current stage of development.
              </Rule>
              <Rule rule="SmallRule">
                Additional context on our rules requiring prototypes and
                prohibiting misleading imagery can be found here. Further
                guidance on crafting an honest and clearly presented project can
                be found here.
              </Rule>
            </Box>
            <Box display="flex" top={25}>
              <Icon icon="check" color={Colors.primary} strokeWidth={3} />
              <Rule left={14} rule="TitleRule">
                Projects can’t fundraise for charity.
              </Rule>
            </Box>
            <Rule rule="SmallRule" top={25}>
              While non-profits are welcome to launch projects on Theatredor,
              projects can’t promise to raise funds to donate to a charity or
              cause. Funds raised on Theatredor must go towards facilitating the
              project outlined by the creator on the project page.
            </Rule>
            <Box display="flex" top={25}>
              <Icon icon="check" color={Colors.primary} strokeWidth={3} />
              <Rule left={14} rule="TitleRule">
                Projects can’t offer equity.
              </Rule>
            </Box>
            <Rule rule="SmallRule" top={25}>
              Investment is not permitted on Theatredor. Projects can’t offer
              incentives like equity, revenue sharing, or investment
              opportunities.
            </Rule>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default OurRulesScreen;
