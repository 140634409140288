import React from "react";
import { Col, Container, Row } from "react-grid-system";
import { connect } from "react-redux";

import {
  Box,
  Rule,
  BigProjectCard,
  SmallProjectCardSkeleton,
  BigSkeleton,
  ProjectCard,
  Button,
} from "../Bitter";
import { CategoriesContainer } from "../Containers";
import CookiePopup from "../Containers/CookiePopup";
import { API } from "../Modules";

class Screen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { location, history } = this.props;
    const { listview = [], listviewloaded = false } = this.props.redux;
    return (
      <>
        <CategoriesContainer />
        <Container>
          <Box
            height="100%"
            width="100%"
            direction="column"
            flex={1}
            display="flex"
            color="#FFFFFF"
          >
            <Row>
              <Col xs={12}>
                <Box display="flex" justify="center">
                  <Rule
                    rule="FooterTitle"
                    size={32}
                    line={32.8}
                    mode="padding"
                    top={50}
                    bottom={50}
                    style={{ textAlign: "center" }}
                  >
                    Creative work shows us what’s possible. Help fund it here.
                  </Rule>
                </Box>
              </Col>
              <Col sm={12} md={7} lg={7} xxl={7}>
                <Box>
                  {listview.length > 0 ? (
                    listview.map((project, index) =>
                      index === 0 ? (
                        <BigProjectCard
                          project={project}
                          key={project._id}
                          id={project._id}
                          title={project.name}
                          image={project.assets[0]?.access}
                          description={project.description}
                        ></BigProjectCard>
                      ) : null
                    )
                  ) : listviewloaded ? (
                    <Box
                      width="100%"
                      align="center"
                      justify="center"
                      direction="column"
                      display="flex"
                    >
                      <img
                        src="/assets/i/empty.svg"
                        className="--block --margin --restriction"
                      />
                      <Box width="100%" style={{ maxWidth: 500 }}>
                        <Rule
                          display="block"
                          rule="Project"
                          size={32}
                          line={32.8}
                        >
                          Start a Project
                        </Rule>
                        <Rule
                          display="block"
                          rule="SmallRule"
                          top={20}
                          bottom={20}
                        >
                          Be one of the first lucky contributors to start a
                          project in Theatredor Crowdfunding. To create a
                          project click on the button down below.
                        </Rule>
                        <a href="/personalverification">
                          <Button>Start a Project</Button>
                        </a>
                      </Box>
                    </Box>
                  ) : (
                    <BigSkeleton />
                  )}
                </Box>
              </Col>
              <Col sm={12} md={5} lg={5} xxl={5}>
                {listview.length > 0 ? (
                  listview.map((project, index) =>
                    index === 0 ? null : (
                      <ProjectCard
                        project={project}
                        key={project._id}
                        id={project._id}
                        title={project.name}
                        description={project.description}
                        backers={project.backers.length}
                        goal={project.projectMilestone / 100}
                        fund={project.collected / 100}
                        image={project.assets[0]?.access}
                        currency={project.projectCurrency}
                      />
                    )
                  )
                ) : (
                  <>
                    <SmallProjectCardSkeleton />
                    <SmallProjectCardSkeleton />
                    <SmallProjectCardSkeleton />
                    <SmallProjectCardSkeleton />
                  </>
                )}
              </Col>
            </Row>
          </Box>
          <CookiePopup />
        </Container>
      </>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Screen);
