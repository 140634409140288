import React from "react";
import { Box, Rule } from "../../Bitter";
import { Colors } from "../../Helpers";

const Component = ({ loading = true }) => {
  if (!loading) {
    return null;
  }
  return (
    <Box
      background="#FFFFFF"
      color="#FFFFFF"
      mode="position"
      width="100%"
      height="100%"
      position="fixed"
      layer={9999}
      display="flex"
      align="center"
      justify="center"
    >
      <img width="32" height="32" src="/assets/i/loading.svg" />
    </Box>
  );
};

export default Component;
