import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container } from "react-grid-system";
import { Box, Input, Rule, Loading } from "../Bitter";
import { API, Auth } from "../Modules";
import {
  CategoriesContainer,
  FooterContainer,
  MenuContainer,
} from "../Containers";
import { Colors, Functions, Resources } from "../Helpers";

import Toast from "react-hot-toast";

const CreatorRequestScreen = ({ redux, core }) => {
  const { user } = redux;

  const [creatorDetails, setCreatorDetails] = useState({});

  const [loading, _loading] = useState(true);

  useEffect(() => {
    if (!user.premium) {
      window.location = "/funding-restriction";
      return false;
    }

    if (!user.isVerified && Resources.persona_required) {
      window.location = "/personalverification";
      return false;
    }

    if (user.metadata.length) {
      window.location = "/startproject";
      return false;
    }

    _loading(false);
  }, []);

  const enterCreatorDetails = async () => {
    if (
      creatorDetails.name_and_surname &&
      creatorDetails.country_of_citizenship &&
      creatorDetails.country_of_residency &&
      creatorDetails.state &&
      creatorDetails.city &&
      creatorDetails.address &&
      creatorDetails.company_email &&
      creatorDetails.company_address &&
      creatorDetails.company_state &&
      creatorDetails.company_city &&
      creatorDetails.ein_vat
    ) {
      if (
        creatorDetails.name_and_surname.length > 0 &&
        creatorDetails.country_of_citizenship.length > 0 &&
        creatorDetails.country_of_residency.length > 0 &&
        creatorDetails.state.length > 0 &&
        creatorDetails.city.length > 0 &&
        creatorDetails.address.length > 0 &&
        creatorDetails.company_email.length > 0 &&
        creatorDetails.company_address.length > 0 &&
        creatorDetails.company_state.length > 0 &&
        creatorDetails.company_city.length > 0 &&
        creatorDetails.ein_vat.length > 0
      ) {
        const response = await API.update(
          "/user/update/" + user._id,
          { metadata: creatorDetails },
          localStorage.getItem("access_token"),
          "put"
        );

        if (response?.data?.updatedUser) {
          core("user", response.data.updatedUser);
          Toast((t) => response?.data.message);
          Auth.update(response.data.updatedUser);

          await Functions.sleep(1000);

          window.location = "/startproject";
        }
      } else {
        Toast.error("Please make sure to enter all of the details required.");
      }
    } else {
      Toast.error("Please make sure to enter all of the details required.");
    }
  };

  return (
    <>
      <Loading loading={loading} />
      <CategoriesContainer />
      <Container>
        <Box height="100%" width="100%" display="flex" justify="center">
          <Box
            direction="column"
            flex={1}
            display="flex"
            color="#FFFFFF"
            align="center"
            justify="center"
          >
            <Box top={50} style={{ maxWidth: "730px" }}>
              <Box display="flex">
                <Rule rule="Project" size={32} line={32.8}>
                  Creator Requirements
                </Rule>

                <Rule left={20} rule="Goal" size={32} line={32.8}>
                  Step 2/3
                </Rule>
              </Box>
              <Rule top={20} rule="SmallRule">
                Anyone that meets our{" "}
                <Rule
                  rule="SmallRule"
                  color={Colors.primary}
                  style={{
                    textDecoration: "underline",
                    display: "inline",
                    fontWeight: "bold",
                  }}
                >
                  Creator Requirements
                </Rule>{" "}
                is eligible to launch a project on{" "}
                <Rule
                  rule="SmallRule"
                  color={Colors.black}
                  style={{
                    fontWeight: "bold",
                    display: "inline",
                  }}
                >
                  Theatredor
                </Rule>
              </Rule>
              <Rule rule="SmallRule" top={20}>
                We encourage you to browse through our{" "}
                <Rule
                  rule="SmallRule"
                  color={Colors.primary}
                  style={{
                    textDecoration: "underline",
                    fontWeight: "bold",
                    display: "inline",
                  }}
                >
                  Creator Handbook
                </Rule>{" "}
                for tips on structuring and running your project, as well as a
                thorough list of resources we've made available for our
                creators. Make sure you take a minute to check{" "}
                <Rule
                  rule="SmallRule"
                  color={Colors.primary}
                  style={{
                    textDecoration: "underline",
                    fontWeight: "bold",
                    display: "inline",
                  }}
                >
                  Our Rules
                </Rule>{" "}
                too.
              </Rule>
              <Box top={50}>
                <Box bottom={15}>
                  <Input
                    name="Name & Surname"
                    placeholder="Jhon Doe"
                    change={(value) =>
                      setCreatorDetails({
                        ...creatorDetails,
                        name_and_surname: value,
                      })
                    }
                  ></Input>
                </Box>
                <Box bottom={15}>
                  <Input
                    name="Country of Citizenship"
                    placeholder="United Kingdom"
                    change={(value) =>
                      setCreatorDetails({
                        ...creatorDetails,
                        country_of_citizenship: value,
                      })
                    }
                  ></Input>
                </Box>
                <Box bottom={15}>
                  <Input
                    name="Country of Residency"
                    placeholder="United Kingdom"
                    change={(value) =>
                      setCreatorDetails({
                        ...creatorDetails,
                        country_of_residency: value,
                      })
                    }
                  ></Input>
                </Box>
                <Box display="flex" justify="space-between" bottom={15}>
                  <Box flex={1} right={15}>
                    <Input
                      name="State"
                      placeholder=""
                      change={(value) =>
                        setCreatorDetails({
                          ...creatorDetails,
                          state: value,
                        })
                      }
                    ></Input>
                  </Box>
                  <Box flex={1} right={15} left={15}>
                    <Input
                      name="City"
                      placeholder=""
                      change={(value) =>
                        setCreatorDetails({
                          ...creatorDetails,
                          city: value,
                        })
                      }
                    ></Input>
                  </Box>
                  <Box flex={1} left={15}>
                    <Input
                      name="Address"
                      placeholder="Sunny Street 1000"
                      change={(value) =>
                        setCreatorDetails({
                          ...creatorDetails,
                          address: value,
                        })
                      }
                    ></Input>
                  </Box>
                </Box>
                <Box bottom={15}>
                  <Input
                    name="Company Email"
                    placeholder="user@company.com"
                    change={(value) =>
                      setCreatorDetails({
                        ...creatorDetails,
                        company_email: value,
                      })
                    }
                  ></Input>
                </Box>
                <Box bottom={15}>
                  <Input
                    name="Company Address"
                    placeholder="Sunny Street 1000"
                    change={(value) =>
                      setCreatorDetails({
                        ...creatorDetails,
                        company_address: value,
                      })
                    }
                  ></Input>
                </Box>
                <Box display="flex" justify="space-between" bottom={15}>
                  <Box flex={1} right={15}>
                    <Input
                      name="State"
                      placeholder=""
                      change={(value) =>
                        setCreatorDetails({
                          ...creatorDetails,
                          company_state: value,
                        })
                      }
                    ></Input>
                  </Box>
                  <Box flex={1} left={15}>
                    <Input
                      name="City"
                      placeholder=""
                      change={(value) =>
                        setCreatorDetails({
                          ...creatorDetails,
                          company_city: value,
                        })
                      }
                    ></Input>
                  </Box>
                </Box>
                <Box bottom={15}>
                  <Input
                    name="EIN/VAT"
                    placeholder="000-000000"
                    change={(value) =>
                      setCreatorDetails({
                        ...creatorDetails,
                        ein_vat: value,
                      })
                    }
                  ></Input>
                </Box>
              </Box>
              <Box
                press={enterCreatorDetails}
                top={50}
                bottom={50}
                display="flex"
                align="center"
                justify="center"
                color={Colors.primary}
                width={115}
                height={40}
                radius={8}
              >
                <Rule rule="BigWhite" size={16} line={16.4}>
                  Continue
                </Rule>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(CreatorRequestScreen);
