import React, { useState, useEffect } from "react";

import { Box, Rule, Icon, Editor } from "../../Bitter";
import { Colors, Functions } from "../../Helpers";
import Lodash from "lodash";

const ContentTab = ({ label, active = false, remove, press, name }) => {
  return active ? (
    <Box
      color={Colors.primary}
      display="flex"
      height={37}
      align="center"
      justify="center"
      radius={8}
      bottom={5}
      right={5}
      style={{ paddingLeft: 12, paddingRight: 12 }}
    >
      <Rule
        right={20}
        rule="Progress"
        size={14}
        line={16.8}
        stlye={{
          paddingTop: "10px",
          paddingBottom: "10px",
          paddingLeft: "20px",
        }}
      >
        <input
          value={label}
          placeholder="Title"
          style={{ width: 60, color: "white", fontWeight: 600 }}
          onChange={(event) => {
            if (name) {
              name(event.target.value);
            }
          }}
        />
      </Rule>
      <Icon color={Colors.white} size={12} icon="x" press={remove}></Icon>
    </Box>
  ) : (
    <Box
      right={5}
      color={Colors.white}
      display="flex"
      height={37}
      align="center"
      justify="center"
      radius={8}
      bottom={5}
      style={{
        border: "1px solid rgba(221, 221, 221, 1)",
        paddingLeft: 12,
        paddingRight: 12,
      }}
    >
      <Rule
        right={20}
        rule="TitleRule"
        size={14}
        line={16.8}
        stlye={{
          paddingTop: "10px",
          paddingBottom: "10px",
          paddingLeft: "20px",
        }}
        press={press}
      >
        {label}
      </Rule>
      <Icon size={12} icon="x" press={remove}></Icon>
    </Box>
  );
};

const ProjectDetailsEditor = ({ content, update }) => {
  const [_content, _setContent] = useState(content);
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contentIndex, setContentIndex] = useState(0);

  useEffect(() => {
    if (!Array.isArray(_content)) {
      _setContent([]);
    }
    setReady(true);
  }, []);

  if (!ready) {
    return null;
  }

  return (
    <Box display="flex" direction="column">
      <Box bottom={5}>
        <Rule rule="TitleRule" size={14} line={16.8}>
          Content
        </Rule>
      </Box>
      <Box display="flex" align="center">
        {_content.map((item, c) => (
          <ContentTab
            press={async () => {
              setContentIndex(c);
              setLoading(true);
              await Functions.sleep(1);
              setLoading(false);
            }}
            label={item.name}
            active={contentIndex === c}
            name={(name) => {
              let clone = Lodash.clone(_content);
              clone[c].name = name;
              _setContent(clone);
              if (update) {
                update(clone);
              }
            }}
            remove={() => {
              let clone = Lodash.clone(_content);
              clone.splice(c, 1);
              _setContent(clone);
              if (update) {
                update(clone);
              }
            }}
          />
        ))}
        <Box
          display="flex"
          radius={8}
          height={37}
          width={37}
          left={5}
          color={Colors.gray}
          align="center"
          justify="center"
          press={() => {
            let clone = Lodash.clone(_content);
            clone.push({ name: "About", blocks: [] });
            _setContent(clone);
            if (update) {
              update(clone);
            }
          }}
        >
          <Icon icon="plus" size={16}></Icon>
        </Box>
      </Box>
      {loading ? null : (
        <Editor
          value={{ blocks: _content[contentIndex]?.blocks }}
          change={({ blocks }) => {
            let temporary = [];
            let clone = Lodash.clone(_content);

            clone.map((item, c) => {
              if (c === contentIndex) {
                temporary.push({
                  name: item.name,
                  blocks,
                });
              } else {
                temporary.push(item);
              }
            });

            _setContent(temporary);
            if (update) {
              update(temporary);
            }
          }}
        />
      )}
    </Box>
  );
};

export default ProjectDetailsEditor;
