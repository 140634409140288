const Resources = {
  url: "https://theatredor-crowdfunding-api.herokuapp.com",
  category: [
    {
      label: "Productions",
      description:
        "Discover the artists and organizations using Theatredor to realize ambitious projects in visual art and performance.",
      slug: "productions",
      links: [
        {
          label: "Theatre",
          description:
            "Discover the artists and organizations using Theatredor to realize ambitious projects in visual art and performance.",
          slug: "theatre",
        },
        {
          label: "Musical Theatre",
          description:
            "Discover the artists and organizations using Theatredor to realize ambitious projects in visual art and performance.",
          slug: "musical-theatre",
        },
        {
          label: "Ballet",
          description:
            "Discover the artists and organizations using Theatredor to realize ambitious projects in visual art and performance.",
          slug: "ballet",
        },
        {
          label: "Dance",
          description:
            "Discover the artists and organizations using Theatredor to realize ambitious projects in visual art and performance.",
          slug: "dance",
        },
      ],
    },
    {
      label: "Development",
      description:
        "Discover the artists and organizations using Theatredor to realize ambitious projects in visual art and performance.",
      slug: "development",
      links: [
        {
          label: "Script Development",
          description:
            "Discover the artists and organizations using Theatredor to realize ambitious projects in visual art and performance.",
          slug: "script-development",
        },
        {
          label: "Personal Development",
          description:
            "Discover the artists and organizations using Theatredor to realize ambitious projects in visual art and performance.",
          slug: "personal-development",
        },
      ],
    },
    {
      label: "Films & TV",
      description:
        "Discover the artists and organizations using Theatredor to realize ambitious projects in visual art and performance.",
      slug: "films-and-tv",
      links: [
        {
          label: "Film Script",
          description:
            "Discover the artists and organizations using Theatredor to realize ambitious projects in visual art and performance.",
          slug: "film-script",
        },
        {
          label: "Film Production",
          description:
            "Discover the artists and organizations using Theatredor to realize ambitious projects in visual art and performance.",
          slug: "film-production",
        },
        {
          label: "TV Script",
          description:
            "Discover the artists and organizations using Theatredor to realize ambitious projects in visual art and performance.",
          slug: "tv-script",
        },
        {
          label: "TV Production",
          description:
            "Discover the artists and organizations using Theatredor to realize ambitious projects in visual art and performance.",
          slug: "tv-production",
        },
      ],
    },
    {
      label: "Educational",
      description:
        "Discover the artists and organizations using Theatredor to realize ambitious projects in visual art and performance.",
      slug: "educational",
      links: [
        {
          label: "Theatre in the Community",
          description:
            "Discover the artists and organizations using Theatredor to realize ambitious projects in visual art and performance.",
          slug: "theatre-in-the-community",
        },
        {
          label: "Schools/College Productions",
          description:
            "Discover the artists and organizations using Theatredor to realize ambitious projects in visual art and performance.",
          slug: "school-college-production",
        },
      ],
    },
  ],
  persona_required: false,
};

export default Resources;
