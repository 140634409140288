import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import Helmet from "react-helmet";
import { Store, Navigator, API, Auth } from "./Modules";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const App = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    Auth.bootstrap();
    setLoaded(true);
  }, []);

  if (!loaded) {
    return null;
  }

  const stripe = loadStripe("pk_test_2pvwDIdEJkDD1sDEFwOxxxgQ");

  return (
    <Provider store={Store}>
      <Helmet>
        <title>Theatredor Crowdfunding</title>
      </Helmet>
      <Elements stripe={stripe}>
        <Navigator />
        <Toaster position="bottom-center" />
      </Elements>
    </Provider>
  );
};

export default App;
