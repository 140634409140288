import "chart.js/auto";
import React from "react";
import { Col, Container, Row } from "react-grid-system";
import { Box, Rule, BarChart, DoughnutChart } from "../Bitter";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  CategoriesContainer,
  FooterContainer,
  MenuContainer,
  ProfileContainer,
} from "../Containers";
import { Colors } from "../Helpers";

const AnalyticsScreen = () => {
  const data = [
    {
      name: "Page A",
      uv: 4000,
      views: 2400,
      amt: 400,
    },
    {
      name: "Page B",
      uv: 3000,
      views: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      views: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      views: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      views: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      views: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      views: 4300,
      amt: 2100,
    },
  ];
  return (
    <>
      <CategoriesContainer />
      <Container>
        <Box
          height="100%"
          width="100%"
          direction="column"
          flex={1}
          display="flex"
          color="#FFFFFF"
          bottom={50}
        >
          <Row>
            <Col sm={12} md={4} lg={4} xxl={4}>
              <ProfileContainer current="analytics" />
            </Col>
            <Col sm={12} md={4} lg={4} xxl={4}>
              <Box align="center" justify="center" top={82}>
                <Rule rule="Project" bottom={25}>
                  Backers
                </Rule>
                <Box
                  display="flex"
                  direction="column"
                  justify="center"
                  align="center"
                  radius={8}
                  style={{
                    border: "1px solid rgba(221, 221, 221, 1)",
                  }}
                  mode="padding"
                  all={25}
                >
                  <BarChart
                    data={[
                      {
                        data: 0,
                        labels: [
                          "0",
                          {
                            text: "February",
                            style: {
                              opacity: 0.5,
                            },
                          },
                        ],
                      },
                      {
                        data: 654,
                        labels: [
                          "654",
                          {
                            text: "March",
                            style: {
                              opacity: 0.5,
                            },
                          },
                        ],
                      },
                      {
                        data: 423,
                        labels: [
                          "423",
                          {
                            text: "April",
                            style: {
                              opacity: 0.5,
                            },
                          },
                        ],
                      },
                      {
                        data: 782,
                        labels: [
                          "782",
                          {
                            text: "May",
                            style: {
                              opacity: 0.5,
                            },
                          },
                        ],
                      },
                      {
                        data: 128,
                        labels: [
                          "128",
                          {
                            text: "June",
                            style: {
                              opacity: 0.5,
                            },
                          },
                        ],
                      },
                      {
                        data: 512,
                        labels: [
                          "512",
                          {
                            text: "July",
                            style: {
                              opacity: 0.5,
                            },
                          },
                        ],
                      },
                    ]}
                    height={150}
                    min={4}
                  />
                  <Box
                    width="100%"
                    display="flex"
                    align="center"
                    justify="space-between"
                    style={{ marginTop: 24 }}
                  >
                    <Box display="flex" direction="column">
                      <Box display="flex" align="center">
                        <Box
                          width={12}
                          height={12}
                          radius={99}
                          color={Colors.primary}
                        ></Box>
                        <Rule left={4} rule="TitleRule" size={10}>
                          Views
                        </Rule>
                      </Box>

                      <Rule rule="TitleRule" size={10}>
                        Total Views
                      </Rule>
                    </Box>

                    <Box
                      display="flex"
                      justify="center"
                      align="center"
                      radius={8}
                      height={24}
                      color={Colors.black}
                      mode="padding"
                      left={10}
                      right={10}
                    >
                      <Rule rule="Display">Export Data</Rule>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Col>
            <Col sm={12} md={4} lg={4} xxl={4}>
              <Box
                display="flex"
                direction="column"
                justify="center"
                align="center"
                radius={8}
                style={{
                  border: "1px solid rgba(221, 221, 221, 1)",
                  marginTop: 133,
                }}
                mode="padding"
                all={25}
              >
                <LineChart width={230} height={145} data={data}>
                  <Line
                    type="monotone"
                    dataKey="views"
                    stroke="#F00000"
                    strokeWidth={2}
                  />
                  <Tooltip />
                </LineChart>

                <Box
                  width="100%"
                  display="flex"
                  align="center"
                  justify="space-between"
                  style={{ marginTop: 24 }}
                >
                  <Box display="flex" direction="column">
                    <Box display="flex" align="center">
                      <Box
                        width={12}
                        height={12}
                        radius={99}
                        color={Colors.primary}
                      ></Box>
                      <Rule left={4} rule="TitleRule" size={10}>
                        Views
                      </Rule>
                    </Box>

                    <Rule rule="TitleRule" size={10}>
                      Total Views
                    </Rule>
                  </Box>

                  <Box
                    display="flex"
                    justify="center"
                    align="center"
                    radius={8}
                    height={24}
                    color={Colors.black}
                    mode="padding"
                    left={10}
                    right={10}
                  >
                    <Rule rule="Display">Export Data</Rule>
                  </Box>
                </Box>
              </Box>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={4} lg={4} xxl={4}>
              <Box></Box>
            </Col>

            <Col sm={12} md={4} lg={4} xxl={4}>
              <Rule rule="Project">Views</Rule>
              <Box
                display="flex"
                direction="column"
                justify="center"
                align="center"
                mode="padding"
                all={25}
                radius={8}
                style={{
                  border: "1px solid rgba(221, 221, 221, 1)",
                  marginTop: 24,
                }}
              >
                <DoughnutChart
                  percentage={25}
                  label={[
                    "512$",
                    {
                      text: "Goal: 2056$",
                      style: {
                        opacity: 0.5,
                      },
                    },
                  ]}
                />
                <Box
                  width="100%"
                  display="flex"
                  align="center"
                  justify="space-between"
                  style={{ marginTop: 24 }}
                >
                  <Box display="flex" direction="column">
                    <Box display="flex" align="center">
                      <Box
                        width={12}
                        height={12}
                        radius={99}
                        color={Colors.primary}
                      ></Box>
                      <Rule left={4} rule="TitleRule" size={10}>
                        Total Pledgers
                      </Rule>
                    </Box>
                    <Box display="flex" align="center">
                      <Box
                        width={12}
                        height={12}
                        radius={99}
                        color={Colors.grey}
                      ></Box>

                      <Rule left={4} rule="TitleRule" size={10}>
                        Goal
                      </Rule>
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    justify="center"
                    align="center"
                    radius={8}
                    height={24}
                    color={Colors.black}
                    mode="padding"
                    left={10}
                    right={10}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <Rule rule="Display">Export Data</Rule>
                  </Box>
                </Box>
              </Box>
            </Col>
            <Col sm={12} md={4} lg={4} xxl={4}>
              <Box
                display="flex"
                direction="column"
                justify="center"
                align="center"
                radius={8}
                style={{
                  border: "1px solid rgba(221, 221, 221, 1)",
                  marginTop: 48,
                }}
                mode="padding"
                all={25}
              >
                <BarChart
                  data={[
                    {
                      data: 0,
                      labels: [
                        "0",
                        {
                          text: "February",
                          style: {
                            opacity: 0.5,
                          },
                        },
                      ],
                    },
                    {
                      data: 654,
                      labels: [
                        "654",
                        {
                          text: "March",
                          style: {
                            opacity: 0.5,
                          },
                        },
                      ],
                    },
                    {
                      data: 423,
                      labels: [
                        "423",
                        {
                          text: "April",
                          style: {
                            opacity: 0.5,
                          },
                        },
                      ],
                    },
                    {
                      data: 782,
                      labels: [
                        "782",
                        {
                          text: "May",
                          style: {
                            opacity: 0.5,
                          },
                        },
                      ],
                    },
                    {
                      data: 128,
                      labels: [
                        "128",
                        {
                          text: "June",
                          style: {
                            opacity: 0.5,
                          },
                        },
                      ],
                    },
                    {
                      data: 512,
                      labels: [
                        "512",
                        {
                          text: "July",
                          style: {
                            opacity: 0.5,
                          },
                        },
                      ],
                    },
                  ]}
                  height={150}
                  min={4}
                />
                <Box
                  width="100%"
                  display="flex"
                  align="center"
                  justify="space-between"
                  style={{ marginTop: 24 }}
                >
                  <Box display="flex" direction="column">
                    <Box display="flex" align="center">
                      <Box
                        width={12}
                        height={12}
                        radius={99}
                        color={Colors.primary}
                      ></Box>
                      <Rule left={4} rule="TitleRule" size={10}>
                        Views
                      </Rule>
                    </Box>

                    <Rule rule="TitleRule" size={10}>
                      Total Views
                    </Rule>
                  </Box>

                  <Box
                    display="flex"
                    justify="center"
                    align="center"
                    radius={8}
                    height={24}
                    color={Colors.black}
                    mode="padding"
                    left={10}
                    right={10}
                  >
                    <Rule rule="Display">Export Data</Rule>
                  </Box>
                </Box>
              </Box>
            </Col>
          </Row>
        </Box>
      </Container>
    </>
  );
};

export default AnalyticsScreen;
